.mat-mdc-checkbox + label {
  @apply opacity-80 pl-2 mt-1 mb-4 block;
}
// Angular Material has hard-coded values
:root {
  --mdc-checkbox-selected-checkmark-color: var(--accent-swatch-2);
  --mdc-checkbox-disabled-selected-icon-color: var(--accent-swatch-2);
  --mdc-checkbox-disabled-unselected-icon-color: var(--disabled);
  --mdc-checkbox-disabled-selected-checkmark-color: var(--disabled);
}
