.mat-mdc-option {
  @apply text-sm min-h-[36px] #{!important};
}

:root {
  .mat-mdc-option:focus.mdc-list-item,
  .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
    background-color: var(--hover);
  }
  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: var(--hover);
  }
  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
      .mat-mdc-option-multiple
    ) {
    background-color: var(--hover);
  }
}
