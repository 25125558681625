@mixin transparent-button-color($palette-name) {
  color: var(--#{$palette-name}-swatch-6);
}

@mixin transparent-button-bg($palette-name) {
  background-color: var(--#{$palette-name}-swatch-3);
}

/** Styles for Tertiary Button */
@mixin secondary-button($palette-name) {
  @include transparent-button-color($palette-name);
  @include transparent-button-bg($palette-name);
  @include button-focus($palette-name);
}

/** Styles for Primary Button */
@mixin primary-button($palette-name) {
  color: var(--#{$palette-name}-swatch-1);
  background-color: var(--#{$palette-name}-swatch-6);

  .mat-mdc-progress-spinner circle,
  .mat-mdc-progress-spinner circle {
    stroke: var(--#{$palette-name}-swatch-1);
  }

  :active,
  &:not(button:disabled):hover,
  &:not(button:disabled):focus-visible {
    background-color: var(--#{$palette-name}-swatch-7);
    color: var(--#{$palette-name}-swatch-2);
    border-color: var(--#{$palette-name}-swatch-7);
  }
}

/** Styles for Button Focus */
@mixin button-focus($palette-name) {
  :active,
  &:not(button:disabled):hover,
  &:not(button:disabled):focus-visible {
    background-color: var(--#{$palette-name}-swatch-3);
    color: var(--#{$palette-name}-swatch-7);
    border-color: var(--#{$palette-name}-swatch-7);
  }
}

.ctx-button {
  @apply outline-none flex flex-row items-center justify-center border-solid border-2 gap-2 transition-all select-none leading-none box-border;
  border-color: transparent;

  &--sans {
    @apply font-sans;
  }

  &--mono {
    @apply font-mono;
  }

  &--secondary {
    &.ctx-button--color-primary {
      @include secondary-button('primary');
    }

    &.ctx-button--color-accent {
      @include secondary-button('accent');
    }

    &.ctx-button--color-warn {
      @include secondary-button('warn');
    }
  }

  &--primary {
    &.ctx-button--color-primary {
      @include primary-button('primary');
    }

    &.ctx-button--color-accent {
      @include primary-button('accent');
    }

    &.ctx-button--color-warn {
      @include primary-button('warn');
    }
  }

  // Text sizes for button
  $sizes: sm, base, lg;

  // Classes for button size
  @each $size in $sizes {
    &--#{$size} {
      @if $size == 'sm' {
        @apply px-2 py-1 rounded-md text-xs;
      }

      @if $size == 'base' {
        @apply px-2 py-1.5 rounded-md text-sm;
      }

      @if $size == 'lg' {
        @apply px-2 py-2 rounded-md text-base;
      }
    }
  }
}
