.mat-mdc-table {
  &.mat-table--paginated {
    .mat-mdc-header-cell,
    .mat-mdc-cell {
      @apply whitespace-nowrap;
    }

    .mat-mdc-header-cell {
      @apply whitespace-nowrap;
    }

    .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
      background-color: inherit;
    }
  }
}
