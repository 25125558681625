@use 'sass:map';

/// Generates an OKLCH palette in CSS variables for a given name and hue.
@mixin generate-variables-for-hue($name, $value) {
  --#{$name}-hue: #{$value};

  --x#{$name}-swatch-1: oklch(0.99 0.02 var(--#{$name}-hue));
  --x#{$name}-swatch-2: oklch(0.97 0.03 var(--#{$name}-hue));
  --x#{$name}-swatch-3: oklch(0.9 0.04 var(--#{$name}-hue));
  --x#{$name}-swatch-4: oklch(0.72 0.13 var(--#{$name}-hue));
  --x#{$name}-swatch-5: oklch(0.67 0.13 var(--#{$name}-hue));
  --x#{$name}-swatch-6: oklch(0.5 0.13 var(--#{$name}-hue));
  --x#{$name}-swatch-7: oklch(0.35 0.13 var(--#{$name}-hue));
  --x#{$name}-swatch-8: oklch(0.25 0.13 var(--#{$name}-hue));
  --x#{$name}-swatch-9: oklch(0.13 0.13 var(--#{$name}-hue));
  --x#{$name}-swatch-10: oklch(0.05 0.13 var(--#{$name}-hue));

  --#{$name}-swatch-1: var(--x#{$name}-swatch-1);
  --#{$name}-swatch-2: var(--x#{$name}-swatch-2);
  --#{$name}-swatch-3: var(--x#{$name}-swatch-3);
  --#{$name}-swatch-4: var(--x#{$name}-swatch-4);
  --#{$name}-swatch-5: var(--x#{$name}-swatch-5);
  --#{$name}-swatch-6: var(--x#{$name}-swatch-6);
  --#{$name}-swatch-7: var(--x#{$name}-swatch-7);
  --#{$name}-swatch-8: var(--x#{$name}-swatch-8);
  --#{$name}-swatch-9: var(--x#{$name}-swatch-9);
  --#{$name}-swatch-10: var(--x#{$name}-swatch-10);
}

/// Generates CSS variables using OKLCH for a Neutral palette.
@mixin generate-variables-for-accent() {
  --accent-hue: var(--primary-hue);
  $chroma: 0.0015;

  --xaccent-swatch-1: oklch(0.99 #{$chroma} var(--accent-hue));
  --xaccent-swatch-2: oklch(0.97 #{$chroma} var(--accent-hue));
  --xaccent-swatch-3: oklch(0.9 #{$chroma} var(--accent-hue));
  --xaccent-swatch-4: oklch(0.72 #{$chroma} var(--accent-hue));
  --xaccent-swatch-5: oklch(0.67 #{$chroma} var(--accent-hue));
  --xaccent-swatch-6: oklch(0.5 #{$chroma} var(--accent-hue));
  --xaccent-swatch-7: oklch(0.4 #{$chroma} var(--accent-hue));
  --xaccent-swatch-8: oklch(0.35 #{$chroma} var(--accent-hue));
  --xaccent-swatch-9: oklch(0.33 #{$chroma} var(--accent-hue));
  --xaccent-swatch-10: oklch(0.25 #{$chroma} var(--accent-hue));

  --accent-swatch-1: var(--xaccent-swatch-1);
  --accent-swatch-2: var(--xaccent-swatch-2);
  --accent-swatch-3: var(--xaccent-swatch-3);
  --accent-swatch-4: var(--xaccent-swatch-4);
  --accent-swatch-5: var(--xaccent-swatch-5);
  --accent-swatch-6: var(--xaccent-swatch-6);
  --accent-swatch-7: var(--xaccent-swatch-7);
  --accent-swatch-8: var(--xaccent-swatch-8);
  --accent-swatch-9: var(--xaccent-swatch-9);
  --accent-swatch-10: var(--xaccent-swatch-10);
}

/// Swap Swatch 1 & Swatch 10, Swatch 2 and Swatch 9, and so on.
@mixin generate-dark-mode($name) {
  --#{$name}-swatch-1: var(--x#{$name}-swatch-10);
  --#{$name}-swatch-2: var(--x#{$name}-swatch-9);
  --#{$name}-swatch-3: var(--x#{$name}-swatch-8);
  --#{$name}-swatch-4: var(--x#{$name}-swatch-7);
  --#{$name}-swatch-5: var(--x#{$name}-swatch-6);
  --#{$name}-swatch-6: var(--x#{$name}-swatch-5);
  --#{$name}-swatch-7: var(--x#{$name}-swatch-4);
  --#{$name}-swatch-8: var(--x#{$name}-swatch-3);
  --#{$name}-swatch-9: var(--x#{$name}-swatch-2);
  --#{$name}-swatch-10: var(--x#{$name}-swatch-1);
}

:root {
  @include generate-variables-for-hue(primary, 240);
  @include generate-variables-for-accent();
  @include generate-variables-for-hue(warn, 24);
  @include generate-variables-for-hue(success, 152);

  --text: var(--accent-swatch-9);
  --muted-text: var(--accent-swatch-6);
  --divider: var(--accent-swatch-3);
  --card: var(--accent-swatch-1);
  --background: var(--accent-swatch-2);
  --elevation: color-mix(in oklch, var(--accent-swatch-9), transparent 95%);
  --disabled: color-mix(in oklch, var(--accent-swatch-6), transparent 81%);
  --hover: color-mix(in oklch, var(--accent-swatch-6), transparent 63%);
}

// Dark mode
[data-theme='dark'] {
  @include generate-dark-mode(primary);
  @include generate-dark-mode(accent);
  @include generate-dark-mode(warn);
  @include generate-dark-mode(success);
}

:root {
  --sans-font: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    sans-serif;
}
