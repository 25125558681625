.ctx-table {
  &__toolbar {
    background-color: var(--card);
    border-color: var(--divider);
    border-bottom-style: solid;
  }

  &__content {
    &__wrapper {
      @apply h-[calc(100vh_-_20.25rem)] w-full overflow-auto box-border;
      background-color: var(--card);
    }

    &__loader {
      @apply h-[calc(100vh_-_20.25rem)] w-full box-border flex justify-center items-center z-10;
      background-color: var(--card);
    }
  }

  &__paginator {
    background-color: var(--card);
    border-color: var(--divider);
  }

  &--object {
    tr > th {
      border-color: var(--divider);
      background-color: var(--card);
    }
  }
}

// Color Overrides
td.mat-mdc-cell,
th.mat-mdc-header-cell {
  border-color: var(--divider);
}

// Bottom border for last row
.mdc-data-table__row:last-child {
  td.mat-mdc-cell {
    border-bottom: 2px dashed var(--divider);
  }
}
