// Webkit & Blink
::-webkit-scrollbar {
  @apply w-2 h-2 p-1;
}

// Seeking buttons for the scrollbars
::-webkit-scrollbar-button {
  display: none;
}

// the draggable scrolling handle.
::-webkit-scrollbar-thumb {
  // @apply rounded-md;
  background-color: color-mix(
    in oklch,
    var(--accent-swatch-8),
    transparent 80%
  );
  &:hover,
  &:active {
    background-color: var(--hover);
  }
}

//  the track (progress bar) of the scrollbar, where there is a gray bar on top of a white bar.
::-webkit-scrollbar-track {
  background-color: var(--accent-swatch-1);
}

// the bottom corner of the scrollbar, where both horizontal and vertical scrollbars meet. This is often the bottom-right corner of the browser window.
::-webkit-scrollbar-corner {
  background-color: var(--accent-swatch-2);
}
