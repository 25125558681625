@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    font-size: inherit;
    background: inherit;
    color: var(--primary-swatch-6);
    outline-color: var(--primary-swatch-6);
    text-decoration: underline;

    &:hover {
      color: var(--primary-swatch-8);
    }

    &:disabled,
    &[disabled] {
      @apply cursor-default;
      opacity: 0.34;
    }
  }

  button {
    @apply font-sans leading-tight m-0 p-0 box-border appearance-none border-0 align-middle text-center cursor-pointer overflow-visible whitespace-nowrap;
    background: transparent;

    user-select: none;
    -webkit-user-select: none;

    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#Firefox
    &::-moz-focus-inner {
      border: 0;
    }

    &:disabled,
    &[disabled] {
      @apply cursor-default;
      opacity: 0.34;
    }
  }

  code {
    @apply px-1;
    // TODO
    background-color: var(--background);
  }

  details {
    @apply my-2 rounded-xl border-2 border-solid box-border;
    border-color: var(--divider);

    // Common elements within get a default padding
    section,
    div,
    span,
    article {
      @apply px-4;
    }

    &[open] {
      summary {
        @apply border-0 border-b-2 border-solid z-10 rounded-b-none;
        background-color: var(--card);
      }
    }
  }

  summary {
    @apply sticky top-0 px-2 py-1 cursor-pointer box-border rounded-xl;
    background-color: var(--card);
    border-color: var(--divider);
  }

  hr {
    @apply mx-0 my-4;
    border-color: var(--divider);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--text);
  }

  h1.display {
    @apply text-center opacity-70 text-4xl mb-8;
  }

  .text-warning {
    // TODO
    color: var(--warn-swatch-6);
  }

  // Generic elements get themed by default.
  body,
  nav,
  div,
  section,
  span,
  form {
    border-color: var(--divider);
  }

  li {
    @apply opacity-80;
    margin-block-start: 1.25em;
    margin-block-end: 1.25em;
  }

  p {
    @apply opacity-80;
  }
}

@layer utilities {
  .custom-table {
    table,
    th,
    td {
      @apply border border-solid;
      border-color: var(--divider);
    }

    th {
      @apply text-left;
    }

    th,
    td {
      @apply p-1;
    }
  }
}

// Allows animation to run but halts them for users with reduced motion enabled in the OS.
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
