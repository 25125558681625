.ctx-button-group {
  @apply inline-flex items-center justify-start;

  .ctx-button {
    @apply relative;

    &:first-child:not(:only-child) {
      @apply rounded-r-none flex-grow;
    }

    &:not(:first-child):not(:last-child):not(:only-child) {
      @apply rounded-none;
    }

    &:last-child:not(:only-child) {
      @apply rounded-l-none;
    }
  }
}
