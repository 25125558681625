.mat-mdc-menu-item {
  @apply text-sm min-h-[36px] #{!important};
}

.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
  // @apply break-words;
}

:root {
  .mat-mdc-menu-item:not([disabled]):hover {
    background-color: var(--hover);
  }
  .mat-mdc-menu-item:not([disabled]):focus {
    background-color: var(--hover);
  }

  .mat-mdc-menu-item:not([disabled]):focus-visible {
    background-color: var(--hover);
  }

  .mat-mdc-menu-item:not([disabled]):focus-within {
    background-color: var(--hover);
  }
  .mat-mdc-menu-item:not([disabled]).mat-mdc-menu-item-highlighted {
    background-color: var(--hover);
  }
}
