// Angular Material helper
@use '@angular/material' as mat;
@use 'sass:map';

// Core styling for Material Angular (pre v15)
@include mat.core();

// Typography for Material Angular (post v15)
/// '6xl': ['3.75rem', { lineHeight: '1' }],
/// '5xl': ['3rem', { lineHeight: '1' }],
/// '4xl': ['2.25rem', { lineHeight: '2.5rem' }]
// '3xl': ['1.875rem', { lineHeight: '2.25rem' }],
// '2xl': ['1.5rem', { lineHeight: '2rem' }],
// xl: ['1.25rem', { lineHeight: '1.75rem' }],
// lg: ['1.125rem', { lineHeight: '1.75rem' }],
// base: ['1rem', { lineHeight: '1.5rem' }],
// sm: ['0.875rem', { lineHeight: '1.25rem' }],
$mat-mdc-typography: mat.define-typography-config(
  $font-family: var(--sans-font),
  $headline-1:
    mat.define-typography-level(3.75rem, 1, 300, var(--sans-font), -0.015625em),
  $headline-2:
    mat.define-typography-level(3rem, 1, 300, var(--sans-font), -0.0083333333em),
  $headline-3: mat.define-typography-level(2.25rem, 1, 400),
  $headline-4:
    mat.define-typography-level(
      1.875rem,
      1,
      400,
      var(--sans-font),
      0.0073529412em
    ),
  $headline-5: mat.define-typography-level(1.5rem, 1, 400),
  $headline-6:
    mat.define-typography-level(1.25rem, 1, 400, var(--sans-font), 0.0125em),
  $subtitle-1: mat.define-typography-level(0.875rem, 1.25rem, 400),
  $subtitle-2: mat.define-typography-level(0.875rem, 1.25rem, 600),
  $body-1:
    mat.define-typography-level(
      0.875rem,
      1.25rem,
      400,
      var(--sans-font),
      0.03125em
    ),
  $body-2:
    mat.define-typography-level(
      0.875rem,
      1.25rem,
      400,
      var(--sans-font),
      0.0178571429em
    ),
  $caption:
    mat.define-typography-level(
      0.75rem,
      1rem,
      400,
      var(--sans-font),
      0.0333333333em
    ),
  $button:
    mat.define-typography-level(
      0.875rem,
      1.75rem,
      400,
      var(--sans-font),
      0.0892857143em
    ),
  $overline:
    mat.define-typography-level(
      0.75rem,
      1rem,
      400,
      var(--sans-font),
      0.1666666667em
    ),
);

// Typography classes
@include mat.typography-hierarchy($mat-mdc-typography);

/// Generates a color map for Angular Material theme using CSS variables
/// WARNING: This color map does not have the is-dark property and the property is set in
/// the generate-material-theme function.
@function generate-material-color-map($prefix) {
  $color-map: (
    50: var(--#{$prefix}-swatch-1),
    100: var(--#{$prefix}-swatch-2),
    200: var(--#{$prefix}-swatch-3),
    300: var(--#{$prefix}-swatch-4),
    400: var(--#{$prefix}-swatch-5),
    500: var(--#{$prefix}-swatch-6),
    600: var(--#{$prefix}-swatch-7),
    700: var(--#{$prefix}-swatch-8),
    800: var(--#{$prefix}-swatch-9),
    900: var(--#{$prefix}-swatch-10),
    default: var(--#{$prefix}-swatch-6),
    lighter: var(--#{$prefix}-swatch-3),
    darker: var(--#{$prefix}-swatch-9),
    text: var(--text),
    default-contrast: var(--#{$prefix}-swatch-9),
    lighter-contrast: var(--#{$prefix}-swatch-9),
    darker-contrast: var(--#{$prefix}-swatch-2),
    '50-contrast': var(--#{$prefix}-swatch-9),
    '100-contrast': var(--#{$prefix}-swatch-9),
    '200-contrast': var(--#{$prefix}-swatch-9),
    '300-contrast': var(--#{$prefix}-swatch-9),
    '400-contrast': var(--#{$prefix}-swatch-9),
    '500-contrast': var(--#{$prefix}-swatch-2),
    '600-contrast': var(--#{$prefix}-swatch-2),
    '700-contrast': var(--#{$prefix}-swatch-2),
    '800-contrast': var(--#{$prefix}-swatch-2),
    '900-contrast': var(--#{$prefix}-swatch-2),
  );

  @return $color-map;
}

// Have to generate two maps with is-dark true and is-dark false because Angular Material hardcodes a lot of colors
// and does not declaritively generate all colors using the color map.
@function generate-material-theme($material-color-map, $is-dark) {
  $material-color-map: map.set($material-color-map, 'is-dark', $is-dark);

  $cryptlex-theme: (
    color: $material-color-map,
    typography: $mat-mdc-typography,
    density: -3,
    primary: map.get($material-color-map, 'primary'),
    accent: map.get($material-color-map, 'accent'),
    warn: map.get($material-color-map, 'warn'),
    foreground: map.get($material-color-map, 'foreground'),
    background: map.get($material-color-map, 'background'),
    is-dark: map.get($material-color-map, 'is-dark'),
  );

  @return $cryptlex-theme;
}

$primary-colors: generate-material-color-map(primary);
$accent-colors: generate-material-color-map(accent);
$warn-colors: generate-material-color-map(warn);

$base-color-map: (
  primary: $primary-colors,
  accent: $accent-colors,
  warn: $warn-colors,
  foreground: (
    base: var(--text),
    divider: var(--divider),
    dividers: var(--divider),
    disabled: var(--disabled),
    disabled-button: var(--disabled),
    disabled-text: var(--disabled),
    elevation: var(--elevation),
    hint-text: var(--muted-text),
    secondary-text: var(--muted-text),
    icon: var(--muted-text),
    icons: var(--muted-text),
    text: var(--text),
    slider-min: var(--primary-swatch-6),
    slider-off: var(--disabled),
    slider-off-active: var(--disabled),
  ),
  background: (
    status-bar: var(--card),
    app-bar: var(--card),
    background: var(--background),
    hover: var(--hover),
    card: var(--card),
    dialog: var(--card),
    disabled-button: var(--disabled),
    raised-button: var(--primary-swatch-6),
    focused-button: var(--primary-swatch-6),
    selected-button: var(--primary-swatch-6),
    selected-disabled-button: var(--disabled),
    disabled-button-toggle: var(--disabled),
    unselected-chip: var(--disabled),
    disabled-list-option: var(--disabled),
    tooltip: var(--accent-swatch-5),
  ),
);

@mixin all-component-themes($theme) {
  @include mat.core-theme($theme);
  @include mat.button-toggle-theme($theme);
  @include mat.autocomplete-theme($theme);
  @include mat.datepicker-theme($theme);
  @include mat.dialog-theme($theme);
  @include mat.icon-theme($theme);
  @include mat.input-theme($theme);
  @include mat.menu-theme($theme);
  @include mat.paginator-theme($theme);
  @include mat.progress-spinner-theme($theme);
  @include mat.radio-theme($theme);
  @include mat.select-theme($theme);
  @include mat.sidenav-theme($theme);
  @include mat.form-field-theme($theme);
  @include mat.table-theme($theme);
  @include mat.checkbox-theme($theme);
  @include mat.tooltip-theme($theme);
  @include mat.snack-bar-theme($theme);
  @include mat.sort-theme($theme);
  @include mat.list-theme($theme);
  @include mat.slider-theme($theme);
  @include mat.slide-toggle-theme($theme);
}

/// Use Material Componenent Themes with is-dark set to false
@include all-component-themes(generate-material-theme($base-color-map, false));

// Dark mode
[data-theme='dark'] {
  /// Use Material Componenent Themes with is-dark set to true
  @include all-component-themes(generate-material-theme($base-color-map, true));
}
