@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  a {
    font-size: inherit;
    background: inherit;
    color: var(--primary-swatch-6);
    outline-color: var(--primary-swatch-6);
    text-decoration: underline;
  }
  a:hover {
    color: var(--primary-swatch-8);
  }
  a:disabled, a[disabled] {
    @apply cursor-default;
    opacity: 0.34;
  }
  button {
    @apply font-sans leading-tight m-0 p-0 box-border appearance-none border-0 align-middle text-center cursor-pointer overflow-visible whitespace-nowrap;
    background: transparent;
    user-select: none;
    -webkit-user-select: none;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  button:disabled, button[disabled] {
    @apply cursor-default;
    opacity: 0.34;
  }
  code {
    @apply px-1;
    background-color: var(--background);
  }
  details {
    @apply my-2 rounded-xl border-2 border-solid box-border;
    border-color: var(--divider);
  }
  details section,
  details div,
  details span,
  details article {
    @apply px-4;
  }
  details[open] summary {
    @apply border-0 border-b-2 border-solid z-10 rounded-b-none;
    background-color: var(--card);
  }
  summary {
    @apply sticky top-0 px-2 py-1 cursor-pointer box-border rounded-xl;
    background-color: var(--card);
    border-color: var(--divider);
  }
  hr {
    @apply mx-0 my-4;
    border-color: var(--divider);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--text);
  }
  h1.display {
    @apply text-center opacity-70 text-4xl mb-8;
  }
  .text-warning {
    color: var(--warn-swatch-6);
  }
  body,
  nav,
  div,
  section,
  span,
  form {
    border-color: var(--divider);
  }
  li {
    @apply opacity-80;
    margin-block-start: 1.25em;
    margin-block-end: 1.25em;
  }
  p {
    @apply opacity-80;
  }
}
@layer utilities {
  .custom-table table,
  .custom-table th,
  .custom-table td {
    @apply border border-solid;
    border-color: var(--divider);
  }
  .custom-table th {
    @apply text-left;
  }
  .custom-table th,
  .custom-table td {
    @apply p-1;
  }
}
@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}
.grecaptcha-badge {
  visibility: hidden;
}

:root {
  --primary-hue: 240;
  --xprimary-swatch-1: oklch(0.99 0.02 var(--primary-hue));
  --xprimary-swatch-2: oklch(0.97 0.03 var(--primary-hue));
  --xprimary-swatch-3: oklch(0.9 0.04 var(--primary-hue));
  --xprimary-swatch-4: oklch(0.72 0.13 var(--primary-hue));
  --xprimary-swatch-5: oklch(0.67 0.13 var(--primary-hue));
  --xprimary-swatch-6: oklch(0.5 0.13 var(--primary-hue));
  --xprimary-swatch-7: oklch(0.35 0.13 var(--primary-hue));
  --xprimary-swatch-8: oklch(0.25 0.13 var(--primary-hue));
  --xprimary-swatch-9: oklch(0.13 0.13 var(--primary-hue));
  --xprimary-swatch-10: oklch(0.05 0.13 var(--primary-hue));
  --primary-swatch-1: var(--xprimary-swatch-1);
  --primary-swatch-2: var(--xprimary-swatch-2);
  --primary-swatch-3: var(--xprimary-swatch-3);
  --primary-swatch-4: var(--xprimary-swatch-4);
  --primary-swatch-5: var(--xprimary-swatch-5);
  --primary-swatch-6: var(--xprimary-swatch-6);
  --primary-swatch-7: var(--xprimary-swatch-7);
  --primary-swatch-8: var(--xprimary-swatch-8);
  --primary-swatch-9: var(--xprimary-swatch-9);
  --primary-swatch-10: var(--xprimary-swatch-10);
  --accent-hue: var(--primary-hue);
  --xaccent-swatch-1: oklch(0.99 0.0015 var(--accent-hue));
  --xaccent-swatch-2: oklch(0.97 0.0015 var(--accent-hue));
  --xaccent-swatch-3: oklch(0.9 0.0015 var(--accent-hue));
  --xaccent-swatch-4: oklch(0.72 0.0015 var(--accent-hue));
  --xaccent-swatch-5: oklch(0.67 0.0015 var(--accent-hue));
  --xaccent-swatch-6: oklch(0.5 0.0015 var(--accent-hue));
  --xaccent-swatch-7: oklch(0.4 0.0015 var(--accent-hue));
  --xaccent-swatch-8: oklch(0.35 0.0015 var(--accent-hue));
  --xaccent-swatch-9: oklch(0.33 0.0015 var(--accent-hue));
  --xaccent-swatch-10: oklch(0.25 0.0015 var(--accent-hue));
  --accent-swatch-1: var(--xaccent-swatch-1);
  --accent-swatch-2: var(--xaccent-swatch-2);
  --accent-swatch-3: var(--xaccent-swatch-3);
  --accent-swatch-4: var(--xaccent-swatch-4);
  --accent-swatch-5: var(--xaccent-swatch-5);
  --accent-swatch-6: var(--xaccent-swatch-6);
  --accent-swatch-7: var(--xaccent-swatch-7);
  --accent-swatch-8: var(--xaccent-swatch-8);
  --accent-swatch-9: var(--xaccent-swatch-9);
  --accent-swatch-10: var(--xaccent-swatch-10);
  --warn-hue: 24;
  --xwarn-swatch-1: oklch(0.99 0.02 var(--warn-hue));
  --xwarn-swatch-2: oklch(0.97 0.03 var(--warn-hue));
  --xwarn-swatch-3: oklch(0.9 0.04 var(--warn-hue));
  --xwarn-swatch-4: oklch(0.72 0.13 var(--warn-hue));
  --xwarn-swatch-5: oklch(0.67 0.13 var(--warn-hue));
  --xwarn-swatch-6: oklch(0.5 0.13 var(--warn-hue));
  --xwarn-swatch-7: oklch(0.35 0.13 var(--warn-hue));
  --xwarn-swatch-8: oklch(0.25 0.13 var(--warn-hue));
  --xwarn-swatch-9: oklch(0.13 0.13 var(--warn-hue));
  --xwarn-swatch-10: oklch(0.05 0.13 var(--warn-hue));
  --warn-swatch-1: var(--xwarn-swatch-1);
  --warn-swatch-2: var(--xwarn-swatch-2);
  --warn-swatch-3: var(--xwarn-swatch-3);
  --warn-swatch-4: var(--xwarn-swatch-4);
  --warn-swatch-5: var(--xwarn-swatch-5);
  --warn-swatch-6: var(--xwarn-swatch-6);
  --warn-swatch-7: var(--xwarn-swatch-7);
  --warn-swatch-8: var(--xwarn-swatch-8);
  --warn-swatch-9: var(--xwarn-swatch-9);
  --warn-swatch-10: var(--xwarn-swatch-10);
  --success-hue: 152;
  --xsuccess-swatch-1: oklch(0.99 0.02 var(--success-hue));
  --xsuccess-swatch-2: oklch(0.97 0.03 var(--success-hue));
  --xsuccess-swatch-3: oklch(0.9 0.04 var(--success-hue));
  --xsuccess-swatch-4: oklch(0.72 0.13 var(--success-hue));
  --xsuccess-swatch-5: oklch(0.67 0.13 var(--success-hue));
  --xsuccess-swatch-6: oklch(0.5 0.13 var(--success-hue));
  --xsuccess-swatch-7: oklch(0.35 0.13 var(--success-hue));
  --xsuccess-swatch-8: oklch(0.25 0.13 var(--success-hue));
  --xsuccess-swatch-9: oklch(0.13 0.13 var(--success-hue));
  --xsuccess-swatch-10: oklch(0.05 0.13 var(--success-hue));
  --success-swatch-1: var(--xsuccess-swatch-1);
  --success-swatch-2: var(--xsuccess-swatch-2);
  --success-swatch-3: var(--xsuccess-swatch-3);
  --success-swatch-4: var(--xsuccess-swatch-4);
  --success-swatch-5: var(--xsuccess-swatch-5);
  --success-swatch-6: var(--xsuccess-swatch-6);
  --success-swatch-7: var(--xsuccess-swatch-7);
  --success-swatch-8: var(--xsuccess-swatch-8);
  --success-swatch-9: var(--xsuccess-swatch-9);
  --success-swatch-10: var(--xsuccess-swatch-10);
  --text: var(--accent-swatch-9);
  --muted-text: var(--accent-swatch-6);
  --divider: var(--accent-swatch-3);
  --card: var(--accent-swatch-1);
  --background: var(--accent-swatch-2);
  --elevation: color-mix(in oklch, var(--accent-swatch-9), transparent 95%);
  --disabled: color-mix(in oklch, var(--accent-swatch-6), transparent 81%);
  --hover: color-mix(in oklch, var(--accent-swatch-6), transparent 63%);
}

[data-theme=dark] {
  --primary-swatch-1: var(--xprimary-swatch-10);
  --primary-swatch-2: var(--xprimary-swatch-9);
  --primary-swatch-3: var(--xprimary-swatch-8);
  --primary-swatch-4: var(--xprimary-swatch-7);
  --primary-swatch-5: var(--xprimary-swatch-6);
  --primary-swatch-6: var(--xprimary-swatch-5);
  --primary-swatch-7: var(--xprimary-swatch-4);
  --primary-swatch-8: var(--xprimary-swatch-3);
  --primary-swatch-9: var(--xprimary-swatch-2);
  --primary-swatch-10: var(--xprimary-swatch-1);
  --accent-swatch-1: var(--xaccent-swatch-10);
  --accent-swatch-2: var(--xaccent-swatch-9);
  --accent-swatch-3: var(--xaccent-swatch-8);
  --accent-swatch-4: var(--xaccent-swatch-7);
  --accent-swatch-5: var(--xaccent-swatch-6);
  --accent-swatch-6: var(--xaccent-swatch-5);
  --accent-swatch-7: var(--xaccent-swatch-4);
  --accent-swatch-8: var(--xaccent-swatch-3);
  --accent-swatch-9: var(--xaccent-swatch-2);
  --accent-swatch-10: var(--xaccent-swatch-1);
  --warn-swatch-1: var(--xwarn-swatch-10);
  --warn-swatch-2: var(--xwarn-swatch-9);
  --warn-swatch-3: var(--xwarn-swatch-8);
  --warn-swatch-4: var(--xwarn-swatch-7);
  --warn-swatch-5: var(--xwarn-swatch-6);
  --warn-swatch-6: var(--xwarn-swatch-5);
  --warn-swatch-7: var(--xwarn-swatch-4);
  --warn-swatch-8: var(--xwarn-swatch-3);
  --warn-swatch-9: var(--xwarn-swatch-2);
  --warn-swatch-10: var(--xwarn-swatch-1);
  --success-swatch-1: var(--xsuccess-swatch-10);
  --success-swatch-2: var(--xsuccess-swatch-9);
  --success-swatch-3: var(--xsuccess-swatch-8);
  --success-swatch-4: var(--xsuccess-swatch-7);
  --success-swatch-5: var(--xsuccess-swatch-6);
  --success-swatch-6: var(--xsuccess-swatch-5);
  --success-swatch-7: var(--xsuccess-swatch-4);
  --success-swatch-8: var(--xsuccess-swatch-3);
  --success-swatch-9: var(--xsuccess-swatch-2);
  --success-swatch-10: var(--xsuccess-swatch-1);
}

:root {
  --sans-font: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    sans-serif;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, transparent);
  color: var(--mat-app-text-color, inherit);
}

.mat-h1,
.mat-headline-5,
.mat-typography .mat-h1,
.mat-typography .mat-headline-5,
.mat-typography h1 {
  font: 400 1.5rem / 1 var(--sans-font);
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  font: 400 1.25rem / 1 var(--sans-font);
  letter-spacing: 0.0125em;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subtitle-1,
.mat-typography .mat-h3,
.mat-typography .mat-subtitle-1,
.mat-typography h3 {
  font: 400 0.875rem / 1.25rem var(--sans-font);
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-body-1,
.mat-typography .mat-h4,
.mat-typography .mat-body-1,
.mat-typography h4 {
  font: 400 0.875rem / 1.25rem var(--sans-font);
  letter-spacing: 0.03125em;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(0.875rem * 0.83) / 1.25rem var(--sans-font);
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(0.875rem * 0.67) / 1.25rem var(--sans-font);
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-subtitle-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-subtitle-2 {
  font: 600 0.875rem / 1.25rem var(--sans-font);
  letter-spacing: normal;
}

.mat-body,
.mat-body-2,
.mat-typography .mat-body,
.mat-typography .mat-body-2,
.mat-typography {
  font: 400 0.875rem / 1.25rem var(--sans-font);
  letter-spacing: 0.0178571429em;
}
.mat-body p,
.mat-body-2 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-2 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 0.75rem / 1rem var(--sans-font);
  letter-spacing: 0.0333333333em;
}

.mat-headline-1,
.mat-typography .mat-headline-1 {
  font: 300 3.75rem / 1 var(--sans-font);
  letter-spacing: -0.015625em;
  margin: 0 0 56px;
}

.mat-headline-2,
.mat-typography .mat-headline-2 {
  font: 300 3rem / 1 var(--sans-font);
  letter-spacing: -0.0083333333em;
  margin: 0 0 64px;
}

.mat-headline-3,
.mat-typography .mat-headline-3 {
  font: 400 2.25rem / 1 var(--sans-font);
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-4,
.mat-typography .mat-headline-4 {
  font: 400 1.875rem / 1 var(--sans-font);
  letter-spacing: 0.0073529412em;
  margin: 0 0 64px;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: var(--primary-swatch-6);
  --mat-option-label-text-color: var(--text);
  --mat-option-hover-state-layer-color: var(--text);
  --mat-option-focus-state-layer-color: var(--text);
  --mat-option-selected-state-layer-color: var(--text);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: var(--accent-swatch-6);
  --mat-option-label-text-color: var(--text);
  --mat-option-hover-state-layer-color: var(--text);
  --mat-option-focus-state-layer-color: var(--text);
  --mat-option-selected-state-layer-color: var(--text);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: var(--warn-swatch-6);
  --mat-option-label-text-color: var(--text);
  --mat-option-hover-state-layer-color: var(--text);
  --mat-option-focus-state-layer-color: var(--text);
  --mat-option-selected-state-layer-color: var(--text);
}

html {
  --mat-optgroup-label-text-color: var(--text);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--primary-swatch-6);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--muted-text);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary-swatch-6);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--accent-swatch-6);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--muted-text);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--accent-swatch-6);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--accent-swatch-6);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--muted-text);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--accent-swatch-6);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--warn-swatch-6);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--muted-text);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--warn-swatch-6);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: var(--background);
  --mat-app-text-color: var(--text);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px var(--elevation), 0px 0px 0px 0px var(--elevation), 0px 0px 0px 0px var(--elevation);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px var(--elevation), 0px 1px 1px 0px var(--elevation), 0px 1px 3px 0px var(--elevation);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px var(--elevation), 0px 2px 2px 0px var(--elevation), 0px 1px 5px 0px var(--elevation);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px var(--elevation), 0px 3px 4px 0px var(--elevation), 0px 1px 8px 0px var(--elevation);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px var(--elevation), 0px 4px 5px 0px var(--elevation), 0px 1px 10px 0px var(--elevation);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px var(--elevation), 0px 5px 8px 0px var(--elevation), 0px 1px 14px 0px var(--elevation);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px var(--elevation), 0px 6px 10px 0px var(--elevation), 0px 1px 18px 0px var(--elevation);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px var(--elevation), 0px 7px 10px 1px var(--elevation), 0px 2px 16px 1px var(--elevation);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px var(--elevation), 0px 8px 10px 1px var(--elevation), 0px 3px 14px 2px var(--elevation);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px var(--elevation), 0px 9px 12px 1px var(--elevation), 0px 3px 16px 2px var(--elevation);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px var(--elevation), 0px 10px 14px 1px var(--elevation), 0px 4px 18px 3px var(--elevation);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px var(--elevation), 0px 11px 15px 1px var(--elevation), 0px 4px 20px 3px var(--elevation);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px var(--elevation), 0px 12px 17px 2px var(--elevation), 0px 5px 22px 4px var(--elevation);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px var(--elevation), 0px 13px 19px 2px var(--elevation), 0px 5px 24px 4px var(--elevation);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px var(--elevation), 0px 14px 21px 2px var(--elevation), 0px 5px 26px 4px var(--elevation);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px var(--elevation), 0px 15px 22px 2px var(--elevation), 0px 6px 28px 5px var(--elevation);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px var(--elevation), 0px 16px 24px 2px var(--elevation), 0px 6px 30px 5px var(--elevation);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px var(--elevation), 0px 17px 26px 2px var(--elevation), 0px 6px 32px 5px var(--elevation);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px var(--elevation), 0px 18px 28px 2px var(--elevation), 0px 7px 34px 6px var(--elevation);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px var(--elevation), 0px 19px 29px 2px var(--elevation), 0px 7px 36px 6px var(--elevation);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px var(--elevation), 0px 20px 31px 3px var(--elevation), 0px 8px 38px 7px var(--elevation);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px var(--elevation), 0px 21px 33px 3px var(--elevation), 0px 8px 40px 7px var(--elevation);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px var(--elevation), 0px 22px 35px 3px var(--elevation), 0px 8px 42px 7px var(--elevation);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px var(--elevation), 0px 23px 36px 3px var(--elevation), 0px 9px 44px 8px var(--elevation);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px var(--elevation), 0px 24px 38px 3px var(--elevation), 0px 9px 46px 8px var(--elevation);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-option-label-text-font: var(--sans-font);
  --mat-option-label-text-line-height: 1.25rem;
  --mat-option-label-text-size: 0.875rem;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: var(--sans-font);
  --mat-optgroup-label-text-line-height: 1.25rem;
  --mat-optgroup-label-text-size: 0.875rem;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: var(--muted-text);
  --mat-legacy-button-toggle-state-layer-color: var(--primary-swatch-6);
  --mat-legacy-button-toggle-selected-state-text-color: var(--muted-text);
  --mat-legacy-button-toggle-selected-state-background-color: var(--primary-swatch-6);
  --mat-legacy-button-toggle-disabled-state-text-color: var(--disabled);
  --mat-legacy-button-toggle-disabled-state-background-color: var(--disabled);
  --mat-legacy-button-toggle-disabled-selected-state-background-color: var(--disabled);
  --mat-standard-button-toggle-text-color: var(--text);
  --mat-standard-button-toggle-background-color: var(--card);
  --mat-standard-button-toggle-state-layer-color: var(--primary-swatch-6);
  --mat-standard-button-toggle-selected-state-background-color: var(--primary-swatch-6);
  --mat-standard-button-toggle-selected-state-text-color: var(--text);
  --mat-standard-button-toggle-disabled-state-text-color: var(--disabled);
  --mat-standard-button-toggle-disabled-state-background-color: var(--card);
  --mat-standard-button-toggle-disabled-selected-state-text-color: var(--text);
  --mat-standard-button-toggle-disabled-selected-state-background-color: var(--disabled);
  --mat-standard-button-toggle-divider-color: var(--divider);
}

html {
  --mat-standard-button-toggle-height: 36px;
}

html {
  --mat-legacy-button-toggle-label-text-font: var(--sans-font);
  --mat-legacy-button-toggle-label-text-line-height: 1.25rem;
  --mat-legacy-button-toggle-label-text-size: 0.875rem;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: var(--sans-font);
  --mat-standard-button-toggle-label-text-line-height: 1.25rem;
  --mat-standard-button-toggle-label-text-size: 0.875rem;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: var(--card);
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--primary-swatch-9);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary-swatch-6);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: var(--disabled);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--primary-swatch-9);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--primary-swatch-6);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--primary-swatch-6);
  --mat-datepicker-toggle-active-state-icon-color: var(--text);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--primary-swatch-6), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: var(--muted-text);
  --mat-datepicker-calendar-body-label-text-color: var(--muted-text);
  --mat-datepicker-calendar-period-button-text-color: var(--text);
  --mat-datepicker-calendar-period-button-icon-color: var(--muted-text);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--muted-text);
  --mat-datepicker-calendar-header-divider-color: var(--divider);
  --mat-datepicker-calendar-header-text-color: var(--muted-text);
  --mat-datepicker-calendar-date-today-outline-color: var(--muted-text);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: var(--disabled);
  --mat-datepicker-calendar-date-text-color: var(--text);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: var(--disabled);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--divider);
  --mat-datepicker-range-input-separator-color: var(--text);
  --mat-datepicker-range-input-disabled-state-separator-color: var(--disabled);
  --mat-datepicker-range-input-disabled-state-text-color: var(--disabled);
  --mat-datepicker-calendar-container-background-color: var(--card);
  --mat-datepicker-calendar-container-text-color: var(--text);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--accent-swatch-9);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--accent-swatch-6);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: var(--disabled);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--accent-swatch-9);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--accent-swatch-6);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--accent-swatch-6);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--accent-swatch-6), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--warn-swatch-9);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--warn-swatch-6);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: var(--disabled);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--warn-swatch-9);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--warn-swatch-6);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--warn-swatch-6);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--warn-swatch-6), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: var(--text);
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: var(--text);
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: var(--sans-font);
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-weight: 400;
  --mat-datepicker-calendar-period-button-text-size: 0.875rem;
  --mat-datepicker-calendar-period-button-text-weight: 400;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: var(--card);
  --mdc-dialog-subhead-color: var(--text);
  --mdc-dialog-supporting-text-color: var(--text);
}

html {
  --mdc-dialog-subhead-font: var(--sans-font);
  --mdc-dialog-subhead-line-height: 1;
  --mdc-dialog-subhead-size: 1.25rem;
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: var(--sans-font);
  --mdc-dialog-supporting-text-line-height: 1.25rem;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: var(--text);
}
.mat-icon.mat-accent {
  --mat-icon-color: var(--text);
}
.mat-icon.mat-warn {
  --mat-icon-color: var(--text);
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
}

html {
  --mat-menu-item-label-text-color: var(--text);
  --mat-menu-item-icon-color: var(--text);
  --mat-menu-item-hover-state-layer-color: var(--text);
  --mat-menu-item-focus-state-layer-color: var(--text);
  --mat-menu-container-color: var(--card);
  --mat-menu-divider-color: var(--divider);
}

html {
  --mat-menu-item-label-text-font: var(--sans-font);
  --mat-menu-item-label-text-size: 0.875rem;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 1.25rem;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mat-paginator-container-text-color: var(--text);
  --mat-paginator-container-background-color: var(--card);
  --mat-paginator-enabled-icon-color: var(--text);
  --mat-paginator-disabled-icon-color: var(--text);
}

html {
  --mat-paginator-container-size: 40px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
}

html {
  --mat-paginator-container-text-font: var(--sans-font);
  --mat-paginator-container-text-line-height: 1rem;
  --mat-paginator-container-text-size: 0.75rem;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 0.75rem;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: var(--primary-swatch-6);
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--accent-swatch-6);
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: var(--warn-swatch-6);
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-color: var(--text);
}
.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--primary-swatch-6);
  --mat-radio-ripple-color: var(--text);
  --mat-radio-checked-ripple-color: var(--primary-swatch-6);
  --mat-radio-disabled-label-color: var(--disabled);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--accent-swatch-6);
  --mat-radio-ripple-color: var(--text);
  --mat-radio-checked-ripple-color: var(--accent-swatch-6);
  --mat-radio-disabled-label-color: var(--disabled);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--warn-swatch-6);
  --mat-radio-ripple-color: var(--text);
  --mat-radio-checked-ripple-color: var(--warn-swatch-6);
  --mat-radio-disabled-label-color: var(--disabled);
}

html {
  --mdc-radio-state-layer-size: 28px;
  --mat-radio-touch-target-display: none;
}

.mat-mdc-radio-button {
  --mdc-form-field-label-text-font: var(--sans-font);
  --mdc-form-field-label-text-line-height: 1.25rem;
  --mdc-form-field-label-text-size: 0.875rem;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: var(--card);
  --mat-select-enabled-trigger-text-color: var(--text);
  --mat-select-disabled-trigger-text-color: var(--disabled);
  --mat-select-placeholder-text-color: var(--text);
  --mat-select-enabled-arrow-color: var(--divider);
  --mat-select-disabled-arrow-color: var(--text);
  --mat-select-focused-arrow-color: var(--primary-swatch-6);
  --mat-select-invalid-arrow-color: var(--warn-swatch-6);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: var(--card);
  --mat-select-enabled-trigger-text-color: var(--text);
  --mat-select-disabled-trigger-text-color: var(--disabled);
  --mat-select-placeholder-text-color: var(--text);
  --mat-select-enabled-arrow-color: var(--divider);
  --mat-select-disabled-arrow-color: var(--text);
  --mat-select-focused-arrow-color: var(--accent-swatch-6);
  --mat-select-invalid-arrow-color: var(--warn-swatch-6);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: var(--card);
  --mat-select-enabled-trigger-text-color: var(--text);
  --mat-select-disabled-trigger-text-color: var(--disabled);
  --mat-select-placeholder-text-color: var(--text);
  --mat-select-enabled-arrow-color: var(--divider);
  --mat-select-disabled-arrow-color: var(--text);
  --mat-select-focused-arrow-color: var(--warn-swatch-6);
  --mat-select-invalid-arrow-color: var(--warn-swatch-6);
}

html {
  --mat-select-arrow-transform: none;
}

html {
  --mat-select-trigger-text-font: var(--sans-font);
  --mat-select-trigger-text-line-height: 1.25rem;
  --mat-select-trigger-text-size: 0.875rem;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: var(--divider);
  --mat-sidenav-container-background-color: var(--card);
  --mat-sidenav-container-text-color: var(--text);
  --mat-sidenav-content-background-color: var(--background);
  --mat-sidenav-content-text-color: var(--text);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: var(--primary-swatch-6);
  --mdc-filled-text-field-focus-active-indicator-color: var(--primary-swatch-6);
  --mdc-filled-text-field-focus-label-text-color: var(--primary-swatch-6);
  --mdc-filled-text-field-container-color: #000;
  --mdc-filled-text-field-disabled-container-color: #000;
  --mdc-filled-text-field-label-text-color: var(--text);
  --mdc-filled-text-field-hover-label-text-color: var(--text);
  --mdc-filled-text-field-disabled-label-text-color: var(--disabled);
  --mdc-filled-text-field-input-text-color: var(--text);
  --mdc-filled-text-field-disabled-input-text-color: var(--disabled);
  --mdc-filled-text-field-input-text-placeholder-color: var(--text);
  --mdc-filled-text-field-error-hover-label-text-color: var(--warn-swatch-6);
  --mdc-filled-text-field-error-focus-label-text-color: var(--warn-swatch-6);
  --mdc-filled-text-field-error-label-text-color: var(--warn-swatch-6);
  --mdc-filled-text-field-error-caret-color: var(--warn-swatch-6);
  --mdc-filled-text-field-active-indicator-color: var(--divider);
  --mdc-filled-text-field-disabled-active-indicator-color: var(--divider);
  --mdc-filled-text-field-hover-active-indicator-color: var(--divider);
  --mdc-filled-text-field-error-active-indicator-color: var(--warn-swatch-6);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--warn-swatch-6);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-caret-color: var(--primary-swatch-6);
  --mdc-outlined-text-field-focus-outline-color: var(--primary-swatch-6);
  --mdc-outlined-text-field-focus-label-text-color: var(--primary-swatch-6);
  --mdc-outlined-text-field-label-text-color: var(--text);
  --mdc-outlined-text-field-hover-label-text-color: var(--text);
  --mdc-outlined-text-field-disabled-label-text-color: var(--disabled);
  --mdc-outlined-text-field-input-text-color: var(--text);
  --mdc-outlined-text-field-disabled-input-text-color: var(--disabled);
  --mdc-outlined-text-field-input-text-placeholder-color: var(--text);
  --mdc-outlined-text-field-error-caret-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-error-label-text-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-outline-color: var(--divider);
  --mdc-outlined-text-field-disabled-outline-color: var(--divider);
  --mdc-outlined-text-field-hover-outline-color: var(--divider);
  --mdc-outlined-text-field-error-focus-outline-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-error-hover-outline-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-error-outline-color: var(--warn-swatch-6);
  --mat-form-field-focus-select-arrow-color: var(--primary-swatch-6);
  --mat-form-field-disabled-input-text-placeholder-color: var(--muted-text);
  --mat-form-field-state-layer-color: var(--text);
  --mat-form-field-error-text-color: var(--warn-swatch-6);
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: var(--muted-text);
  --mat-form-field-disabled-select-arrow-color: var(--muted-text);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: var(--accent-swatch-6);
  --mdc-filled-text-field-focus-active-indicator-color: var(--accent-swatch-6);
  --mdc-filled-text-field-focus-label-text-color: var(--accent-swatch-6);
  --mdc-outlined-text-field-caret-color: var(--accent-swatch-6);
  --mdc-outlined-text-field-focus-outline-color: var(--accent-swatch-6);
  --mdc-outlined-text-field-focus-label-text-color: var(--accent-swatch-6);
  --mat-form-field-focus-select-arrow-color: var(--accent-swatch-6);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: var(--warn-swatch-6);
  --mdc-filled-text-field-focus-active-indicator-color: var(--warn-swatch-6);
  --mdc-filled-text-field-focus-label-text-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-caret-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-focus-outline-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-focus-label-text-color: var(--warn-swatch-6);
  --mat-form-field-focus-select-arrow-color: var(--warn-swatch-6);
}

html {
  --mat-form-field-container-height: 44px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 10px;
  --mat-form-field-filled-with-label-container-padding-top: 10px;
  --mat-form-field-filled-with-label-container-padding-bottom: 10px;
}

html {
  --mdc-filled-text-field-label-text-font: var(--sans-font);
  --mdc-filled-text-field-label-text-size: 0.875rem;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: var(--sans-font);
  --mdc-outlined-text-field-label-text-size: 0.875rem;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: var(--sans-font);
  --mat-form-field-container-text-line-height: 1.25rem;
  --mat-form-field-container-text-size: 0.875rem;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 0.875rem;
  --mat-form-field-subscript-text-font: var(--sans-font);
  --mat-form-field-subscript-text-line-height: 1rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: var(--card);
  --mat-table-header-headline-color: var(--text);
  --mat-table-row-item-label-text-color: var(--text);
  --mat-table-row-item-outline-color: var(--divider);
}

html {
  --mat-table-header-container-height: 44px;
  --mat-table-footer-container-height: 40px;
  --mat-table-row-item-container-height: 40px;
}

html {
  --mat-table-header-headline-font: var(--sans-font);
  --mat-table-header-headline-line-height: 1.25rem;
  --mat-table-header-headline-size: 0.875rem;
  --mat-table-header-headline-weight: 600;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: var(--sans-font);
  --mat-table-row-item-label-text-line-height: 1.25rem;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: var(--sans-font);
  --mat-table-footer-supporting-text-line-height: 1.25rem;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--accent-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
  --mat-checkbox-disabled-label-color: var(--disabled);
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-color: var(--text);
}
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--primary-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--warn-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
}

html {
  --mdc-checkbox-state-layer-size: 28px;
  --mat-checkbox-touch-target-display: none;
}

.mat-mdc-checkbox {
  --mdc-form-field-label-text-font: var(--sans-font);
  --mdc-form-field-label-text-line-height: 1.25rem;
  --mdc-form-field-label-text-size: 0.875rem;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: var(--accent-swatch-5);
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: var(--sans-font);
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: var(--card);
  --mdc-snackbar-supporting-text-color: var(--card);
  --mat-snack-bar-button-color: var(--text);
}

html {
  --mdc-snackbar-supporting-text-font: var(--sans-font);
  --mdc-snackbar-supporting-text-line-height: 1.25rem;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-sort-arrow-color: var(--muted-text);
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
}

html {
  --mdc-list-list-item-label-text-color: var(--text);
  --mdc-list-list-item-supporting-text-color: var(--muted-text);
  --mdc-list-list-item-leading-icon-color: var(--text);
  --mdc-list-list-item-trailing-supporting-text-color: var(--muted-text);
  --mdc-list-list-item-trailing-icon-color: var(--text);
  --mdc-list-list-item-selected-trailing-icon-color: var(--text);
  --mdc-list-list-item-disabled-label-text-color: var(--text);
  --mdc-list-list-item-disabled-leading-icon-color: var(--text);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--text);
  --mdc-list-list-item-hover-label-text-color: var(--text);
  --mdc-list-list-item-hover-leading-icon-color: var(--text);
  --mdc-list-list-item-hover-trailing-icon-color: var(--text);
  --mdc-list-list-item-focus-label-text-color: var(--text);
  --mdc-list-list-item-hover-state-layer-color: var(--text);
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: var(--text);
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--primary-swatch-6);
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--accent-swatch-6);
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--warn-swatch-6);
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--primary-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--accent-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--warn-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--primary-swatch-6);
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--primary-swatch-6);
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 36px;
  --mdc-list-list-item-two-line-container-height: 52px;
  --mdc-list-list-item-three-line-container-height: 76px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 28px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 44px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 60px;
}

html {
  --mdc-list-list-item-label-text-font: var(--sans-font);
  --mdc-list-list-item-label-text-line-height: 1.25rem;
  --mdc-list-list-item-label-text-size: 0.875rem;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: var(--sans-font);
  --mdc-list-list-item-supporting-text-line-height: 1.25rem;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: var(--sans-font);
  --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.75rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 0.875rem / 1.25rem var(--sans-font);
  letter-spacing: normal;
}

html {
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
}

html {
  --mdc-slider-handle-color: var(--primary-swatch-6);
  --mdc-slider-focus-handle-color: var(--primary-swatch-6);
  --mdc-slider-hover-handle-color: var(--primary-swatch-6);
  --mdc-slider-active-track-color: var(--primary-swatch-6);
  --mdc-slider-inactive-track-color: var(--primary-swatch-6);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--primary-swatch-6);
  --mdc-slider-with-tick-marks-active-container-color: var(--primary-swatch-9);
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(var(--elevation), 0.2), 0px 1px 1px 0px rgba(var(--elevation), 0.14), 0px 1px 3px 0px rgba(var(--elevation), 0.12);
  --mat-slider-ripple-color: var(--primary-swatch-6);
  --mat-slider-hover-state-layer-color: rgba(var(--primary-swatch-6), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--primary-swatch-6), 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mat-slider-ripple-color: var(--accent-swatch-6);
  --mat-slider-hover-state-layer-color: rgba(var(--accent-swatch-6), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--accent-swatch-6), 0.2);
  --mdc-slider-handle-color: var(--accent-swatch-6);
  --mdc-slider-focus-handle-color: var(--accent-swatch-6);
  --mdc-slider-hover-handle-color: var(--accent-swatch-6);
  --mdc-slider-active-track-color: var(--accent-swatch-6);
  --mdc-slider-inactive-track-color: var(--accent-swatch-6);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--accent-swatch-6);
  --mdc-slider-with-tick-marks-active-container-color: var(--accent-swatch-9);
}
html .mat-warn {
  --mat-slider-ripple-color: var(--warn-swatch-6);
  --mat-slider-hover-state-layer-color: rgba(var(--warn-swatch-6), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--warn-swatch-6), 0.2);
  --mdc-slider-handle-color: var(--warn-swatch-6);
  --mdc-slider-focus-handle-color: var(--warn-swatch-6);
  --mdc-slider-hover-handle-color: var(--warn-swatch-6);
  --mdc-slider-active-track-color: var(--warn-swatch-6);
  --mdc-slider-inactive-track-color: var(--warn-swatch-6);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--warn-swatch-6);
  --mdc-slider-with-tick-marks-active-container-color: var(--warn-swatch-9);
}

html {
  --mdc-slider-label-label-text-font: var(--sans-font);
  --mdc-slider-label-label-text-size: 0.875rem;
  --mdc-slider-label-label-text-line-height: 1.25rem;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 600;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: var(--primary-swatch-7);
  --mdc-switch-selected-handle-color: var(--primary-swatch-7);
  --mdc-switch-selected-hover-state-layer-color: var(--primary-swatch-7);
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-swatch-7);
  --mdc-switch-selected-focus-handle-color: var(--primary-swatch-10);
  --mdc-switch-selected-hover-handle-color: var(--primary-swatch-10);
  --mdc-switch-selected-pressed-handle-color: var(--primary-swatch-10);
  --mdc-switch-selected-focus-track-color: var(--primary-swatch-4);
  --mdc-switch-selected-hover-track-color: var(--primary-swatch-4);
  --mdc-switch-selected-pressed-track-color: var(--primary-swatch-4);
  --mdc-switch-selected-track-color: var(--primary-swatch-4);
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(var(--elevation), 0.2), 0px 1px 1px 0px rgba(var(--elevation), 0.14), 0px 1px 3px 0px rgba(var(--elevation), 0.12);
  --mdc-switch-handle-shadow-color: var(--elevation);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(var(--elevation), 0.2), 0px 0px 0px 0px rgba(var(--elevation), 0.14), 0px 0px 0px 0px rgba(var(--elevation), 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-disabled-label-text-color: var(--disabled);
}
html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: var(--text);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--accent-swatch-7);
  --mdc-switch-selected-handle-color: var(--accent-swatch-7);
  --mdc-switch-selected-hover-state-layer-color: var(--accent-swatch-7);
  --mdc-switch-selected-pressed-state-layer-color: var(--accent-swatch-7);
  --mdc-switch-selected-focus-handle-color: var(--accent-swatch-10);
  --mdc-switch-selected-hover-handle-color: var(--accent-swatch-10);
  --mdc-switch-selected-pressed-handle-color: var(--accent-swatch-10);
  --mdc-switch-selected-focus-track-color: var(--accent-swatch-4);
  --mdc-switch-selected-hover-track-color: var(--accent-swatch-4);
  --mdc-switch-selected-pressed-track-color: var(--accent-swatch-4);
  --mdc-switch-selected-track-color: var(--accent-swatch-4);
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: var(--warn-swatch-7);
  --mdc-switch-selected-handle-color: var(--warn-swatch-7);
  --mdc-switch-selected-hover-state-layer-color: var(--warn-swatch-7);
  --mdc-switch-selected-pressed-state-layer-color: var(--warn-swatch-7);
  --mdc-switch-selected-focus-handle-color: var(--warn-swatch-10);
  --mdc-switch-selected-hover-handle-color: var(--warn-swatch-10);
  --mdc-switch-selected-pressed-handle-color: var(--warn-swatch-10);
  --mdc-switch-selected-focus-track-color: var(--warn-swatch-4);
  --mdc-switch-selected-hover-track-color: var(--warn-swatch-4);
  --mdc-switch-selected-pressed-track-color: var(--warn-swatch-4);
  --mdc-switch-selected-track-color: var(--warn-swatch-4);
}

html {
  --mdc-switch-state-layer-size: 28px;
}

html .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: var(--sans-font);
  --mdc-form-field-label-text-line-height: 1.25rem;
  --mdc-form-field-label-text-size: 0.875rem;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

[data-theme=dark] {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: var(--primary-swatch-6);
  --mat-option-label-text-color: var(--text);
  --mat-option-hover-state-layer-color: var(--text);
  --mat-option-focus-state-layer-color: var(--text);
  --mat-option-selected-state-layer-color: var(--text);
  --mat-optgroup-label-text-color: var(--text);
  --mat-full-pseudo-checkbox-selected-icon-color: var(--accent-swatch-6);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--muted-text);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--accent-swatch-6);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: var(--background);
  --mat-app-text-color: var(--text);
  --mat-option-label-text-font: var(--sans-font);
  --mat-option-label-text-line-height: 1.25rem;
  --mat-option-label-text-size: 0.875rem;
  --mat-option-label-text-tracking: 0.03125em;
  --mat-option-label-text-weight: 400;
  --mat-optgroup-label-text-font: var(--sans-font);
  --mat-optgroup-label-text-line-height: 1.25rem;
  --mat-optgroup-label-text-size: 0.875rem;
  --mat-optgroup-label-text-tracking: 0.03125em;
  --mat-optgroup-label-text-weight: 400;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: var(--muted-text);
  --mat-legacy-button-toggle-state-layer-color: var(--primary-swatch-6);
  --mat-legacy-button-toggle-selected-state-text-color: var(--muted-text);
  --mat-legacy-button-toggle-selected-state-background-color: var(--primary-swatch-6);
  --mat-legacy-button-toggle-disabled-state-text-color: var(--disabled);
  --mat-legacy-button-toggle-disabled-state-background-color: var(--disabled);
  --mat-legacy-button-toggle-disabled-selected-state-background-color: var(--disabled);
  --mat-standard-button-toggle-text-color: var(--text);
  --mat-standard-button-toggle-background-color: var(--card);
  --mat-standard-button-toggle-state-layer-color: var(--primary-swatch-6);
  --mat-standard-button-toggle-selected-state-background-color: var(--primary-swatch-6);
  --mat-standard-button-toggle-selected-state-text-color: var(--text);
  --mat-standard-button-toggle-disabled-state-text-color: var(--disabled);
  --mat-standard-button-toggle-disabled-state-background-color: var(--card);
  --mat-standard-button-toggle-disabled-selected-state-text-color: var(--text);
  --mat-standard-button-toggle-disabled-selected-state-background-color: var(--disabled);
  --mat-standard-button-toggle-divider-color: var(--divider);
  --mat-standard-button-toggle-height: 36px;
  --mat-legacy-button-toggle-label-text-font: var(--sans-font);
  --mat-legacy-button-toggle-label-text-line-height: 1.25rem;
  --mat-legacy-button-toggle-label-text-size: 0.875rem;
  --mat-legacy-button-toggle-label-text-tracking: 0.03125em;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: var(--sans-font);
  --mat-standard-button-toggle-label-text-line-height: 1.25rem;
  --mat-standard-button-toggle-label-text-size: 0.875rem;
  --mat-standard-button-toggle-label-text-tracking: 0.03125em;
  --mat-standard-button-toggle-label-text-weight: 400;
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: var(--card);
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: var(--primary-swatch-9);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary-swatch-6);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: var(--disabled);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--primary-swatch-9);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--primary-swatch-6);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--primary-swatch-6);
  --mat-datepicker-toggle-active-state-icon-color: var(--text);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--primary-swatch-6), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: var(--muted-text);
  --mat-datepicker-calendar-body-label-text-color: var(--muted-text);
  --mat-datepicker-calendar-period-button-text-color: var(--text);
  --mat-datepicker-calendar-period-button-icon-color: var(--muted-text);
  --mat-datepicker-calendar-navigation-button-icon-color: var(--muted-text);
  --mat-datepicker-calendar-header-divider-color: var(--divider);
  --mat-datepicker-calendar-header-text-color: var(--muted-text);
  --mat-datepicker-calendar-date-today-outline-color: var(--muted-text);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: var(--disabled);
  --mat-datepicker-calendar-date-text-color: var(--text);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: var(--disabled);
  --mat-datepicker-calendar-date-preview-state-outline-color: var(--divider);
  --mat-datepicker-range-input-separator-color: var(--text);
  --mat-datepicker-range-input-disabled-state-separator-color: var(--disabled);
  --mat-datepicker-range-input-disabled-state-text-color: var(--disabled);
  --mat-datepicker-calendar-container-background-color: var(--card);
  --mat-datepicker-calendar-container-text-color: var(--text);
  --mat-datepicker-calendar-text-font: var(--sans-font);
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 0.875rem;
  --mat-datepicker-calendar-body-label-text-weight: 400;
  --mat-datepicker-calendar-period-button-text-size: 0.875rem;
  --mat-datepicker-calendar-period-button-text-weight: 400;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
  --mdc-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-dialog-container-shadow-color: #000;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: var(--card);
  --mdc-dialog-subhead-color: var(--text);
  --mdc-dialog-supporting-text-color: var(--text);
  --mdc-dialog-subhead-font: var(--sans-font);
  --mdc-dialog-subhead-line-height: 1;
  --mdc-dialog-subhead-size: 1.25rem;
  --mdc-dialog-subhead-weight: 400;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: var(--sans-font);
  --mdc-dialog-supporting-text-line-height: 1.25rem;
  --mdc-dialog-supporting-text-size: 0.875rem;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.03125em;
  --mat-icon-color: inherit;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-item-label-text-color: var(--text);
  --mat-menu-item-icon-color: var(--text);
  --mat-menu-item-hover-state-layer-color: var(--text);
  --mat-menu-item-focus-state-layer-color: var(--text);
  --mat-menu-container-color: var(--card);
  --mat-menu-divider-color: var(--divider);
  --mat-menu-item-label-text-font: var(--sans-font);
  --mat-menu-item-label-text-size: 0.875rem;
  --mat-menu-item-label-text-tracking: 0.03125em;
  --mat-menu-item-label-text-line-height: 1.25rem;
  --mat-menu-item-label-text-weight: 400;
  --mat-paginator-container-text-color: var(--text);
  --mat-paginator-container-background-color: var(--card);
  --mat-paginator-enabled-icon-color: var(--text);
  --mat-paginator-disabled-icon-color: var(--text);
  --mat-paginator-container-size: 40px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-container-text-font: var(--sans-font);
  --mat-paginator-container-text-line-height: 1rem;
  --mat-paginator-container-text-size: 0.75rem;
  --mat-paginator-container-text-tracking: 0.0333333333em;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 0.75rem;
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: var(--primary-swatch-6);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-radio-state-layer-size: 28px;
  --mat-radio-touch-target-display: none;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: var(--card);
  --mat-select-enabled-trigger-text-color: var(--text);
  --mat-select-disabled-trigger-text-color: var(--disabled);
  --mat-select-placeholder-text-color: var(--text);
  --mat-select-enabled-arrow-color: var(--divider);
  --mat-select-disabled-arrow-color: var(--text);
  --mat-select-focused-arrow-color: var(--primary-swatch-6);
  --mat-select-invalid-arrow-color: var(--warn-swatch-6);
  --mat-select-arrow-transform: none;
  --mat-select-trigger-text-font: var(--sans-font);
  --mat-select-trigger-text-line-height: 1.25rem;
  --mat-select-trigger-text-size: 0.875rem;
  --mat-select-trigger-text-tracking: 0.03125em;
  --mat-select-trigger-text-weight: 400;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: var(--divider);
  --mat-sidenav-container-background-color: var(--card);
  --mat-sidenav-container-text-color: var(--text);
  --mat-sidenav-content-background-color: var(--background);
  --mat-sidenav-content-text-color: var(--text);
  --mat-sidenav-scrim-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: var(--primary-swatch-6);
  --mdc-filled-text-field-focus-active-indicator-color: var(--primary-swatch-6);
  --mdc-filled-text-field-focus-label-text-color: var(--primary-swatch-6);
  --mdc-filled-text-field-container-color: #fff;
  --mdc-filled-text-field-disabled-container-color: #fff;
  --mdc-filled-text-field-label-text-color: var(--text);
  --mdc-filled-text-field-hover-label-text-color: var(--text);
  --mdc-filled-text-field-disabled-label-text-color: var(--disabled);
  --mdc-filled-text-field-input-text-color: var(--text);
  --mdc-filled-text-field-disabled-input-text-color: var(--disabled);
  --mdc-filled-text-field-input-text-placeholder-color: var(--text);
  --mdc-filled-text-field-error-hover-label-text-color: var(--warn-swatch-6);
  --mdc-filled-text-field-error-focus-label-text-color: var(--warn-swatch-6);
  --mdc-filled-text-field-error-label-text-color: var(--warn-swatch-6);
  --mdc-filled-text-field-error-caret-color: var(--warn-swatch-6);
  --mdc-filled-text-field-active-indicator-color: var(--divider);
  --mdc-filled-text-field-disabled-active-indicator-color: var(--divider);
  --mdc-filled-text-field-hover-active-indicator-color: var(--divider);
  --mdc-filled-text-field-error-active-indicator-color: var(--warn-swatch-6);
  --mdc-filled-text-field-error-focus-active-indicator-color: var(--warn-swatch-6);
  --mdc-filled-text-field-error-hover-active-indicator-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-caret-color: var(--primary-swatch-6);
  --mdc-outlined-text-field-focus-outline-color: var(--primary-swatch-6);
  --mdc-outlined-text-field-focus-label-text-color: var(--primary-swatch-6);
  --mdc-outlined-text-field-label-text-color: var(--text);
  --mdc-outlined-text-field-hover-label-text-color: var(--text);
  --mdc-outlined-text-field-disabled-label-text-color: var(--disabled);
  --mdc-outlined-text-field-input-text-color: var(--text);
  --mdc-outlined-text-field-disabled-input-text-color: var(--disabled);
  --mdc-outlined-text-field-input-text-placeholder-color: var(--text);
  --mdc-outlined-text-field-error-caret-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-error-label-text-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-outline-color: var(--divider);
  --mdc-outlined-text-field-disabled-outline-color: var(--divider);
  --mdc-outlined-text-field-hover-outline-color: var(--divider);
  --mdc-outlined-text-field-error-focus-outline-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-error-hover-outline-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-error-outline-color: var(--warn-swatch-6);
  --mat-form-field-focus-select-arrow-color: var(--primary-swatch-6);
  --mat-form-field-disabled-input-text-placeholder-color: var(--muted-text);
  --mat-form-field-state-layer-color: var(--text);
  --mat-form-field-error-text-color: var(--warn-swatch-6);
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: var(--muted-text);
  --mat-form-field-disabled-select-arrow-color: var(--muted-text);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-form-field-container-height: 44px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 10px;
  --mat-form-field-filled-with-label-container-padding-top: 10px;
  --mat-form-field-filled-with-label-container-padding-bottom: 10px;
  --mdc-filled-text-field-label-text-font: var(--sans-font);
  --mdc-filled-text-field-label-text-size: 0.875rem;
  --mdc-filled-text-field-label-text-tracking: 0.03125em;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: var(--sans-font);
  --mdc-outlined-text-field-label-text-size: 0.875rem;
  --mdc-outlined-text-field-label-text-tracking: 0.03125em;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: var(--sans-font);
  --mat-form-field-container-text-line-height: 1.25rem;
  --mat-form-field-container-text-size: 0.875rem;
  --mat-form-field-container-text-tracking: 0.03125em;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 0.875rem;
  --mat-form-field-subscript-text-font: var(--sans-font);
  --mat-form-field-subscript-text-line-height: 1rem;
  --mat-form-field-subscript-text-size: 0.75rem;
  --mat-form-field-subscript-text-tracking: 0.0333333333em;
  --mat-form-field-subscript-text-weight: 400;
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: var(--card);
  --mat-table-header-headline-color: var(--text);
  --mat-table-row-item-label-text-color: var(--text);
  --mat-table-row-item-outline-color: var(--divider);
  --mat-table-header-container-height: 44px;
  --mat-table-footer-container-height: 40px;
  --mat-table-row-item-container-height: 40px;
  --mat-table-header-headline-font: var(--sans-font);
  --mat-table-header-headline-line-height: 1.25rem;
  --mat-table-header-headline-size: 0.875rem;
  --mat-table-header-headline-weight: 600;
  --mat-table-header-headline-tracking: normal;
  --mat-table-row-item-label-text-font: var(--sans-font);
  --mat-table-row-item-label-text-line-height: 1.25rem;
  --mat-table-row-item-label-text-size: 0.875rem;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: var(--sans-font);
  --mat-table-footer-supporting-text-line-height: 1.25rem;
  --mat-table-footer-supporting-text-size: 0.875rem;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--accent-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
  --mat-checkbox-disabled-label-color: var(--disabled);
  --mdc-checkbox-state-layer-size: 28px;
  --mat-checkbox-touch-target-display: none;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: var(--accent-swatch-5);
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-plain-tooltip-supporting-text-font: var(--sans-font);
  --mdc-plain-tooltip-supporting-text-size: 0.75rem;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0.0333333333em;
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: var(--card);
  --mdc-snackbar-supporting-text-color: var(--card);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mdc-snackbar-supporting-text-font: var(--sans-font);
  --mdc-snackbar-supporting-text-line-height: 1.25rem;
  --mdc-snackbar-supporting-text-size: 0.875rem;
  --mdc-snackbar-supporting-text-weight: 400;
  --mat-sort-arrow-color: var(--muted-text);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 0;
  --mdc-list-list-item-label-text-color: var(--text);
  --mdc-list-list-item-supporting-text-color: var(--muted-text);
  --mdc-list-list-item-leading-icon-color: var(--text);
  --mdc-list-list-item-trailing-supporting-text-color: var(--muted-text);
  --mdc-list-list-item-trailing-icon-color: var(--text);
  --mdc-list-list-item-selected-trailing-icon-color: var(--text);
  --mdc-list-list-item-disabled-label-text-color: var(--text);
  --mdc-list-list-item-disabled-leading-icon-color: var(--text);
  --mdc-list-list-item-disabled-trailing-icon-color: var(--text);
  --mdc-list-list-item-hover-label-text-color: var(--text);
  --mdc-list-list-item-hover-leading-icon-color: var(--text);
  --mdc-list-list-item-hover-trailing-icon-color: var(--text);
  --mdc-list-list-item-focus-label-text-color: var(--text);
  --mdc-list-list-item-hover-state-layer-color: var(--text);
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: var(--text);
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mdc-list-list-item-one-line-container-height: 36px;
  --mdc-list-list-item-two-line-container-height: 52px;
  --mdc-list-list-item-three-line-container-height: 76px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
  --mdc-list-list-item-label-text-font: var(--sans-font);
  --mdc-list-list-item-label-text-line-height: 1.25rem;
  --mdc-list-list-item-label-text-size: 0.875rem;
  --mdc-list-list-item-label-text-tracking: 0.03125em;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: var(--sans-font);
  --mdc-list-list-item-supporting-text-line-height: 1.25rem;
  --mdc-list-list-item-supporting-text-size: 0.875rem;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: var(--sans-font);
  --mdc-list-list-item-trailing-supporting-text-line-height: 1rem;
  --mdc-list-list-item-trailing-supporting-text-size: 0.75rem;
  --mdc-list-list-item-trailing-supporting-text-tracking: 0.0333333333em;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-color: var(--primary-swatch-6);
  --mdc-slider-focus-handle-color: var(--primary-swatch-6);
  --mdc-slider-hover-handle-color: var(--primary-swatch-6);
  --mdc-slider-active-track-color: var(--primary-swatch-6);
  --mdc-slider-inactive-track-color: var(--primary-swatch-6);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--primary-swatch-6);
  --mdc-slider-with-tick-marks-active-container-color: var(--primary-swatch-9);
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(var(--elevation), 0.2), 0px 1px 1px 0px rgba(var(--elevation), 0.14), 0px 1px 3px 0px rgba(var(--elevation), 0.12);
  --mat-slider-ripple-color: var(--primary-swatch-6);
  --mat-slider-hover-state-layer-color: rgba(var(--primary-swatch-6), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--primary-swatch-6), 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mdc-slider-label-label-text-font: var(--sans-font);
  --mdc-slider-label-label-text-size: 0.875rem;
  --mdc-slider-label-label-text-line-height: 1.25rem;
  --mdc-slider-label-label-text-tracking: normal;
  --mdc-slider-label-label-text-weight: 600;
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
  --mdc-switch-selected-focus-state-layer-color: var(--primary-swatch-4);
  --mdc-switch-selected-handle-color: var(--primary-swatch-4);
  --mdc-switch-selected-hover-state-layer-color: var(--primary-swatch-4);
  --mdc-switch-selected-pressed-state-layer-color: var(--primary-swatch-4);
  --mdc-switch-selected-focus-handle-color: var(--primary-swatch-3);
  --mdc-switch-selected-hover-handle-color: var(--primary-swatch-3);
  --mdc-switch-selected-pressed-handle-color: var(--primary-swatch-3);
  --mdc-switch-selected-focus-track-color: var(--primary-swatch-7);
  --mdc-switch-selected-hover-track-color: var(--primary-swatch-7);
  --mdc-switch-selected-pressed-track-color: var(--primary-swatch-7);
  --mdc-switch-selected-track-color: var(--primary-swatch-7);
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(var(--elevation), 0.2), 0px 1px 1px 0px rgba(var(--elevation), 0.14), 0px 1px 3px 0px rgba(var(--elevation), 0.12);
  --mdc-switch-handle-shadow-color: var(--elevation);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(var(--elevation), 0.2), 0px 0px 0px 0px rgba(var(--elevation), 0.14), 0px 0px 0px 0px rgba(var(--elevation), 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-disabled-label-text-color: var(--disabled);
  --mdc-switch-state-layer-size: 28px;
}
[data-theme=dark] .mat-accent {
  --mat-option-selected-state-label-text-color: var(--accent-swatch-6);
  --mat-option-label-text-color: var(--text);
  --mat-option-hover-state-layer-color: var(--text);
  --mat-option-focus-state-layer-color: var(--text);
  --mat-option-selected-state-layer-color: var(--text);
}
[data-theme=dark] .mat-warn {
  --mat-option-selected-state-label-text-color: var(--warn-swatch-6);
  --mat-option-label-text-color: var(--text);
  --mat-option-hover-state-layer-color: var(--text);
  --mat-option-focus-state-layer-color: var(--text);
  --mat-option-selected-state-layer-color: var(--text);
}
[data-theme=dark] .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--primary-swatch-6);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--muted-text);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--primary-swatch-6);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
[data-theme=dark] .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--accent-swatch-6);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--muted-text);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--accent-swatch-6);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
[data-theme=dark] .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: var(--warn-swatch-6);
  --mat-full-pseudo-checkbox-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-unselected-icon-color: var(--muted-text);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: var(--background);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--warn-swatch-6);
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
[data-theme=dark] .mat-elevation-z0, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px var(--elevation), 0px 0px 0px 0px var(--elevation), 0px 0px 0px 0px var(--elevation);
}
[data-theme=dark] .mat-elevation-z1, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px var(--elevation), 0px 1px 1px 0px var(--elevation), 0px 1px 3px 0px var(--elevation);
}
[data-theme=dark] .mat-elevation-z2, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px var(--elevation), 0px 2px 2px 0px var(--elevation), 0px 1px 5px 0px var(--elevation);
}
[data-theme=dark] .mat-elevation-z3, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px var(--elevation), 0px 3px 4px 0px var(--elevation), 0px 1px 8px 0px var(--elevation);
}
[data-theme=dark] .mat-elevation-z4, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px var(--elevation), 0px 4px 5px 0px var(--elevation), 0px 1px 10px 0px var(--elevation);
}
[data-theme=dark] .mat-elevation-z5, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px var(--elevation), 0px 5px 8px 0px var(--elevation), 0px 1px 14px 0px var(--elevation);
}
[data-theme=dark] .mat-elevation-z6, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px var(--elevation), 0px 6px 10px 0px var(--elevation), 0px 1px 18px 0px var(--elevation);
}
[data-theme=dark] .mat-elevation-z7, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px var(--elevation), 0px 7px 10px 1px var(--elevation), 0px 2px 16px 1px var(--elevation);
}
[data-theme=dark] .mat-elevation-z8, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px var(--elevation), 0px 8px 10px 1px var(--elevation), 0px 3px 14px 2px var(--elevation);
}
[data-theme=dark] .mat-elevation-z9, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px var(--elevation), 0px 9px 12px 1px var(--elevation), 0px 3px 16px 2px var(--elevation);
}
[data-theme=dark] .mat-elevation-z10, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px var(--elevation), 0px 10px 14px 1px var(--elevation), 0px 4px 18px 3px var(--elevation);
}
[data-theme=dark] .mat-elevation-z11, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px var(--elevation), 0px 11px 15px 1px var(--elevation), 0px 4px 20px 3px var(--elevation);
}
[data-theme=dark] .mat-elevation-z12, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px var(--elevation), 0px 12px 17px 2px var(--elevation), 0px 5px 22px 4px var(--elevation);
}
[data-theme=dark] .mat-elevation-z13, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px var(--elevation), 0px 13px 19px 2px var(--elevation), 0px 5px 24px 4px var(--elevation);
}
[data-theme=dark] .mat-elevation-z14, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px var(--elevation), 0px 14px 21px 2px var(--elevation), 0px 5px 26px 4px var(--elevation);
}
[data-theme=dark] .mat-elevation-z15, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px var(--elevation), 0px 15px 22px 2px var(--elevation), 0px 6px 28px 5px var(--elevation);
}
[data-theme=dark] .mat-elevation-z16, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px var(--elevation), 0px 16px 24px 2px var(--elevation), 0px 6px 30px 5px var(--elevation);
}
[data-theme=dark] .mat-elevation-z17, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px var(--elevation), 0px 17px 26px 2px var(--elevation), 0px 6px 32px 5px var(--elevation);
}
[data-theme=dark] .mat-elevation-z18, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px var(--elevation), 0px 18px 28px 2px var(--elevation), 0px 7px 34px 6px var(--elevation);
}
[data-theme=dark] .mat-elevation-z19, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px var(--elevation), 0px 19px 29px 2px var(--elevation), 0px 7px 36px 6px var(--elevation);
}
[data-theme=dark] .mat-elevation-z20, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px var(--elevation), 0px 20px 31px 3px var(--elevation), 0px 8px 38px 7px var(--elevation);
}
[data-theme=dark] .mat-elevation-z21, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px var(--elevation), 0px 21px 33px 3px var(--elevation), 0px 8px 40px 7px var(--elevation);
}
[data-theme=dark] .mat-elevation-z22, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px var(--elevation), 0px 22px 35px 3px var(--elevation), 0px 8px 42px 7px var(--elevation);
}
[data-theme=dark] .mat-elevation-z23, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px var(--elevation), 0px 23px 36px 3px var(--elevation), 0px 9px 44px 8px var(--elevation);
}
[data-theme=dark] .mat-elevation-z24, [data-theme=dark] .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px var(--elevation), 0px 24px 38px 3px var(--elevation), 0px 9px 46px 8px var(--elevation);
}
.mat-theme-loaded-marker {
  display: none;
}

[data-theme=dark] .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--accent-swatch-9);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--accent-swatch-6);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: var(--disabled);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--accent-swatch-9);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--accent-swatch-6);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--accent-swatch-6);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--accent-swatch-6), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
[data-theme=dark] .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: var(--warn-swatch-9);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--warn-swatch-6);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: var(--disabled);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(--warn-swatch-9);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--warn-swatch-6);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--warn-swatch-6);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--warn-swatch-6), 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
[data-theme=dark] .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: var(--text);
}
[data-theme=dark] .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: var(--text);
}
[data-theme=dark] .mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
[data-theme=dark] .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
[data-theme=dark] .mat-icon.mat-primary {
  --mat-icon-color: var(--text);
}
[data-theme=dark] .mat-icon.mat-accent {
  --mat-icon-color: var(--text);
}
[data-theme=dark] .mat-icon.mat-warn {
  --mat-icon-color: var(--text);
}
[data-theme=dark] .mat-accent {
  --mdc-circular-progress-active-indicator-color: var(--accent-swatch-6);
}
[data-theme=dark] .mat-warn {
  --mdc-circular-progress-active-indicator-color: var(--warn-swatch-6);
}
[data-theme=dark] .mat-mdc-radio-button {
  --mdc-form-field-label-text-color: var(--text);
}
[data-theme=dark] .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--primary-swatch-6);
  --mat-radio-ripple-color: var(--text);
  --mat-radio-checked-ripple-color: var(--primary-swatch-6);
  --mat-radio-disabled-label-color: var(--disabled);
}
[data-theme=dark] .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--accent-swatch-6);
  --mat-radio-ripple-color: var(--text);
  --mat-radio-checked-ripple-color: var(--accent-swatch-6);
  --mat-radio-disabled-label-color: var(--disabled);
}
[data-theme=dark] .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--warn-swatch-6);
  --mat-radio-ripple-color: var(--text);
  --mat-radio-checked-ripple-color: var(--warn-swatch-6);
  --mat-radio-disabled-label-color: var(--disabled);
}
[data-theme=dark] .mat-mdc-radio-button {
  --mdc-form-field-label-text-font: var(--sans-font);
  --mdc-form-field-label-text-line-height: 1.25rem;
  --mdc-form-field-label-text-size: 0.875rem;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}
[data-theme=dark] .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: var(--card);
  --mat-select-enabled-trigger-text-color: var(--text);
  --mat-select-disabled-trigger-text-color: var(--disabled);
  --mat-select-placeholder-text-color: var(--text);
  --mat-select-enabled-arrow-color: var(--divider);
  --mat-select-disabled-arrow-color: var(--text);
  --mat-select-focused-arrow-color: var(--accent-swatch-6);
  --mat-select-invalid-arrow-color: var(--warn-swatch-6);
}
[data-theme=dark] .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: var(--card);
  --mat-select-enabled-trigger-text-color: var(--text);
  --mat-select-disabled-trigger-text-color: var(--disabled);
  --mat-select-placeholder-text-color: var(--text);
  --mat-select-enabled-arrow-color: var(--divider);
  --mat-select-disabled-arrow-color: var(--text);
  --mat-select-focused-arrow-color: var(--warn-swatch-6);
  --mat-select-invalid-arrow-color: var(--warn-swatch-6);
}
[data-theme=dark] .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: var(--accent-swatch-6);
  --mdc-filled-text-field-focus-active-indicator-color: var(--accent-swatch-6);
  --mdc-filled-text-field-focus-label-text-color: var(--accent-swatch-6);
  --mdc-outlined-text-field-caret-color: var(--accent-swatch-6);
  --mdc-outlined-text-field-focus-outline-color: var(--accent-swatch-6);
  --mdc-outlined-text-field-focus-label-text-color: var(--accent-swatch-6);
  --mat-form-field-focus-select-arrow-color: var(--accent-swatch-6);
}
[data-theme=dark] .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: var(--warn-swatch-6);
  --mdc-filled-text-field-focus-active-indicator-color: var(--warn-swatch-6);
  --mdc-filled-text-field-focus-label-text-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-caret-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-focus-outline-color: var(--warn-swatch-6);
  --mdc-outlined-text-field-focus-label-text-color: var(--warn-swatch-6);
  --mat-form-field-focus-select-arrow-color: var(--warn-swatch-6);
}
[data-theme=dark] .mat-mdc-checkbox {
  --mdc-form-field-label-text-color: var(--text);
}
[data-theme=dark] .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--primary-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
}
[data-theme=dark] .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--warn-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
}
[data-theme=dark] .mat-mdc-checkbox {
  --mdc-form-field-label-text-font: var(--sans-font);
  --mdc-form-field-label-text-line-height: 1.25rem;
  --mdc-form-field-label-text-size: 0.875rem;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}
[data-theme=dark] .mdc-list-item__start,
[data-theme=dark] .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-icon-color: var(--primary-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--primary-swatch-6);
}
[data-theme=dark] .mat-accent .mdc-list-item__start,
[data-theme=dark] .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-icon-color: var(--accent-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--accent-swatch-6);
}
[data-theme=dark] .mat-warn .mdc-list-item__start,
[data-theme=dark] .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: var(--muted-text);
  --mdc-radio-disabled-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: var(--muted-text);
  --mdc-radio-unselected-pressed-icon-color: var(--muted-text);
  --mdc-radio-selected-focus-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-hover-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-icon-color: var(--warn-swatch-6);
  --mdc-radio-selected-pressed-icon-color: var(--warn-swatch-6);
}
[data-theme=dark] .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--primary-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--primary-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--primary-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
}
[data-theme=dark] .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--accent-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--accent-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--accent-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
}
[data-theme=dark] .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: var(--text);
  --mdc-checkbox-disabled-unselected-icon-color: var(--text);
  --mdc-checkbox-selected-checkmark-color: var(--warn-swatch-9);
  --mdc-checkbox-selected-focus-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-hover-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-pressed-icon-color: var(--warn-swatch-6);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: var(--text);
  --mdc-checkbox-unselected-pressed-icon-color: var(--text);
  --mdc-checkbox-selected-focus-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-hover-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--warn-swatch-6);
  --mdc-checkbox-unselected-focus-state-layer-color: var(--text);
  --mdc-checkbox-unselected-hover-state-layer-color: var(--text);
  --mdc-checkbox-unselected-pressed-state-layer-color: var(--text);
}
[data-theme=dark] .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
[data-theme=dark] .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: var(--primary-swatch-6);
}
[data-theme=dark] .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
[data-theme=dark] .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: var(--primary-swatch-6);
}
[data-theme=dark] .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
[data-theme=dark] .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
[data-theme=dark] .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
[data-theme=dark] .mdc-list-item__start,
[data-theme=dark] .mdc-list-item__end {
  --mdc-radio-state-layer-size: 28px;
}
[data-theme=dark] .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, [data-theme=dark] .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, [data-theme=dark] .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 44px;
}
[data-theme=dark] .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, [data-theme=dark] .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, [data-theme=dark] .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 60px;
}
[data-theme=dark] .mdc-list-group__subheader {
  font: 400 0.875rem / 1.25rem var(--sans-font);
  letter-spacing: normal;
}
[data-theme=dark] .mat-accent {
  --mat-slider-ripple-color: var(--accent-swatch-6);
  --mat-slider-hover-state-layer-color: rgba(var(--accent-swatch-6), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--accent-swatch-6), 0.2);
  --mdc-slider-handle-color: var(--accent-swatch-6);
  --mdc-slider-focus-handle-color: var(--accent-swatch-6);
  --mdc-slider-hover-handle-color: var(--accent-swatch-6);
  --mdc-slider-active-track-color: var(--accent-swatch-6);
  --mdc-slider-inactive-track-color: var(--accent-swatch-6);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--accent-swatch-6);
  --mdc-slider-with-tick-marks-active-container-color: var(--accent-swatch-9);
}
[data-theme=dark] .mat-warn {
  --mat-slider-ripple-color: var(--warn-swatch-6);
  --mat-slider-hover-state-layer-color: rgba(var(--warn-swatch-6), 0.05);
  --mat-slider-focus-state-layer-color: rgba(var(--warn-swatch-6), 0.2);
  --mdc-slider-handle-color: var(--warn-swatch-6);
  --mdc-slider-focus-handle-color: var(--warn-swatch-6);
  --mdc-slider-hover-handle-color: var(--warn-swatch-6);
  --mdc-slider-active-track-color: var(--warn-swatch-6);
  --mdc-slider-inactive-track-color: var(--warn-swatch-6);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--warn-swatch-6);
  --mdc-slider-with-tick-marks-active-container-color: var(--warn-swatch-9);
}
[data-theme=dark] .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-color: var(--text);
}
[data-theme=dark] .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--accent-swatch-4);
  --mdc-switch-selected-handle-color: var(--accent-swatch-4);
  --mdc-switch-selected-hover-state-layer-color: var(--accent-swatch-4);
  --mdc-switch-selected-pressed-state-layer-color: var(--accent-swatch-4);
  --mdc-switch-selected-focus-handle-color: var(--accent-swatch-3);
  --mdc-switch-selected-hover-handle-color: var(--accent-swatch-3);
  --mdc-switch-selected-pressed-handle-color: var(--accent-swatch-3);
  --mdc-switch-selected-focus-track-color: var(--accent-swatch-7);
  --mdc-switch-selected-hover-track-color: var(--accent-swatch-7);
  --mdc-switch-selected-pressed-track-color: var(--accent-swatch-7);
  --mdc-switch-selected-track-color: var(--accent-swatch-7);
}
[data-theme=dark] .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: var(--warn-swatch-4);
  --mdc-switch-selected-handle-color: var(--warn-swatch-4);
  --mdc-switch-selected-hover-state-layer-color: var(--warn-swatch-4);
  --mdc-switch-selected-pressed-state-layer-color: var(--warn-swatch-4);
  --mdc-switch-selected-focus-handle-color: var(--warn-swatch-3);
  --mdc-switch-selected-hover-handle-color: var(--warn-swatch-3);
  --mdc-switch-selected-pressed-handle-color: var(--warn-swatch-3);
  --mdc-switch-selected-focus-track-color: var(--warn-swatch-7);
  --mdc-switch-selected-hover-track-color: var(--warn-swatch-7);
  --mdc-switch-selected-pressed-track-color: var(--warn-swatch-7);
  --mdc-switch-selected-track-color: var(--warn-swatch-7);
}
[data-theme=dark] .mat-mdc-slide-toggle {
  --mdc-form-field-label-text-font: var(--sans-font);
  --mdc-form-field-label-text-line-height: 1.25rem;
  --mdc-form-field-label-text-size: 0.875rem;
  --mdc-form-field-label-text-tracking: 0.0178571429em;
  --mdc-form-field-label-text-weight: 400;
}

:root {
  --font-sans: "";
  --foreground: var(--text);
  --card-foreground: var(--text);
  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;
  --primary: var(--primary-swatch-6);
  --primary-foreground: 0 0% 98%;
  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: var(--accent-swatch-6);
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;
  --border: var(--divider);
  --input: 240 5.9% 90%;
  --ring: 240 5.9% 10%;
  --radius: 0.5rem;
}

.fib, .fi {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.fi {
  position: relative;
  display: inline-block;
  width: 1.333333em;
  line-height: 1em;
}

.fi:before {
  content: " ";
}

.fi.fis {
  width: 1em;
}

.fi-xx {
  background-image: url(../flags/4x3/xx.svg);
}

.fi-xx.fis {
  background-image: url(../flags/1x1/xx.svg);
}

.fi-ad {
  background-image: url(../flags/4x3/ad.svg);
}

.fi-ad.fis {
  background-image: url(../flags/1x1/ad.svg);
}

.fi-ae {
  background-image: url(../flags/4x3/ae.svg);
}

.fi-ae.fis {
  background-image: url(../flags/1x1/ae.svg);
}

.fi-af {
  background-image: url(../flags/4x3/af.svg);
}

.fi-af.fis {
  background-image: url(../flags/1x1/af.svg);
}

.fi-ag {
  background-image: url(../flags/4x3/ag.svg);
}

.fi-ag.fis {
  background-image: url(../flags/1x1/ag.svg);
}

.fi-ai {
  background-image: url(../flags/4x3/ai.svg);
}

.fi-ai.fis {
  background-image: url(../flags/1x1/ai.svg);
}

.fi-al {
  background-image: url(../flags/4x3/al.svg);
}

.fi-al.fis {
  background-image: url(../flags/1x1/al.svg);
}

.fi-am {
  background-image: url(../flags/4x3/am.svg);
}

.fi-am.fis {
  background-image: url(../flags/1x1/am.svg);
}

.fi-ao {
  background-image: url(../flags/4x3/ao.svg);
}

.fi-ao.fis {
  background-image: url(../flags/1x1/ao.svg);
}

.fi-aq {
  background-image: url(../flags/4x3/aq.svg);
}

.fi-aq.fis {
  background-image: url(../flags/1x1/aq.svg);
}

.fi-ar {
  background-image: url(../flags/4x3/ar.svg);
}

.fi-ar.fis {
  background-image: url(../flags/1x1/ar.svg);
}

.fi-as {
  background-image: url(../flags/4x3/as.svg);
}

.fi-as.fis {
  background-image: url(../flags/1x1/as.svg);
}

.fi-at {
  background-image: url(../flags/4x3/at.svg);
}

.fi-at.fis {
  background-image: url(../flags/1x1/at.svg);
}

.fi-au {
  background-image: url(../flags/4x3/au.svg);
}

.fi-au.fis {
  background-image: url(../flags/1x1/au.svg);
}

.fi-aw {
  background-image: url(../flags/4x3/aw.svg);
}

.fi-aw.fis {
  background-image: url(../flags/1x1/aw.svg);
}

.fi-ax {
  background-image: url(../flags/4x3/ax.svg);
}

.fi-ax.fis {
  background-image: url(../flags/1x1/ax.svg);
}

.fi-az {
  background-image: url(../flags/4x3/az.svg);
}

.fi-az.fis {
  background-image: url(../flags/1x1/az.svg);
}

.fi-ba {
  background-image: url(../flags/4x3/ba.svg);
}

.fi-ba.fis {
  background-image: url(../flags/1x1/ba.svg);
}

.fi-bb {
  background-image: url(../flags/4x3/bb.svg);
}

.fi-bb.fis {
  background-image: url(../flags/1x1/bb.svg);
}

.fi-bd {
  background-image: url(../flags/4x3/bd.svg);
}

.fi-bd.fis {
  background-image: url(../flags/1x1/bd.svg);
}

.fi-be {
  background-image: url(../flags/4x3/be.svg);
}

.fi-be.fis {
  background-image: url(../flags/1x1/be.svg);
}

.fi-bf {
  background-image: url(../flags/4x3/bf.svg);
}

.fi-bf.fis {
  background-image: url(../flags/1x1/bf.svg);
}

.fi-bg {
  background-image: url(../flags/4x3/bg.svg);
}

.fi-bg.fis {
  background-image: url(../flags/1x1/bg.svg);
}

.fi-bh {
  background-image: url(../flags/4x3/bh.svg);
}

.fi-bh.fis {
  background-image: url(../flags/1x1/bh.svg);
}

.fi-bi {
  background-image: url(../flags/4x3/bi.svg);
}

.fi-bi.fis {
  background-image: url(../flags/1x1/bi.svg);
}

.fi-bj {
  background-image: url(../flags/4x3/bj.svg);
}

.fi-bj.fis {
  background-image: url(../flags/1x1/bj.svg);
}

.fi-bl {
  background-image: url(../flags/4x3/bl.svg);
}

.fi-bl.fis {
  background-image: url(../flags/1x1/bl.svg);
}

.fi-bm {
  background-image: url(../flags/4x3/bm.svg);
}

.fi-bm.fis {
  background-image: url(../flags/1x1/bm.svg);
}

.fi-bn {
  background-image: url(../flags/4x3/bn.svg);
}

.fi-bn.fis {
  background-image: url(../flags/1x1/bn.svg);
}

.fi-bo {
  background-image: url(../flags/4x3/bo.svg);
}

.fi-bo.fis {
  background-image: url(../flags/1x1/bo.svg);
}

.fi-bq {
  background-image: url(../flags/4x3/bq.svg);
}

.fi-bq.fis {
  background-image: url(../flags/1x1/bq.svg);
}

.fi-br {
  background-image: url(../flags/4x3/br.svg);
}

.fi-br.fis {
  background-image: url(../flags/1x1/br.svg);
}

.fi-bs {
  background-image: url(../flags/4x3/bs.svg);
}

.fi-bs.fis {
  background-image: url(../flags/1x1/bs.svg);
}

.fi-bt {
  background-image: url(../flags/4x3/bt.svg);
}

.fi-bt.fis {
  background-image: url(../flags/1x1/bt.svg);
}

.fi-bv {
  background-image: url(../flags/4x3/bv.svg);
}

.fi-bv.fis {
  background-image: url(../flags/1x1/bv.svg);
}

.fi-bw {
  background-image: url(../flags/4x3/bw.svg);
}

.fi-bw.fis {
  background-image: url(../flags/1x1/bw.svg);
}

.fi-by {
  background-image: url(../flags/4x3/by.svg);
}

.fi-by.fis {
  background-image: url(../flags/1x1/by.svg);
}

.fi-bz {
  background-image: url(../flags/4x3/bz.svg);
}

.fi-bz.fis {
  background-image: url(../flags/1x1/bz.svg);
}

.fi-ca {
  background-image: url(../flags/4x3/ca.svg);
}

.fi-ca.fis {
  background-image: url(../flags/1x1/ca.svg);
}

.fi-cc {
  background-image: url(../flags/4x3/cc.svg);
}

.fi-cc.fis {
  background-image: url(../flags/1x1/cc.svg);
}

.fi-cd {
  background-image: url(../flags/4x3/cd.svg);
}

.fi-cd.fis {
  background-image: url(../flags/1x1/cd.svg);
}

.fi-cf {
  background-image: url(../flags/4x3/cf.svg);
}

.fi-cf.fis {
  background-image: url(../flags/1x1/cf.svg);
}

.fi-cg {
  background-image: url(../flags/4x3/cg.svg);
}

.fi-cg.fis {
  background-image: url(../flags/1x1/cg.svg);
}

.fi-ch {
  background-image: url(../flags/4x3/ch.svg);
}

.fi-ch.fis {
  background-image: url(../flags/1x1/ch.svg);
}

.fi-ci {
  background-image: url(../flags/4x3/ci.svg);
}

.fi-ci.fis {
  background-image: url(../flags/1x1/ci.svg);
}

.fi-ck {
  background-image: url(../flags/4x3/ck.svg);
}

.fi-ck.fis {
  background-image: url(../flags/1x1/ck.svg);
}

.fi-cl {
  background-image: url(../flags/4x3/cl.svg);
}

.fi-cl.fis {
  background-image: url(../flags/1x1/cl.svg);
}

.fi-cm {
  background-image: url(../flags/4x3/cm.svg);
}

.fi-cm.fis {
  background-image: url(../flags/1x1/cm.svg);
}

.fi-cn {
  background-image: url(../flags/4x3/cn.svg);
}

.fi-cn.fis {
  background-image: url(../flags/1x1/cn.svg);
}

.fi-co {
  background-image: url(../flags/4x3/co.svg);
}

.fi-co.fis {
  background-image: url(../flags/1x1/co.svg);
}

.fi-cr {
  background-image: url(../flags/4x3/cr.svg);
}

.fi-cr.fis {
  background-image: url(../flags/1x1/cr.svg);
}

.fi-cu {
  background-image: url(../flags/4x3/cu.svg);
}

.fi-cu.fis {
  background-image: url(../flags/1x1/cu.svg);
}

.fi-cv {
  background-image: url(../flags/4x3/cv.svg);
}

.fi-cv.fis {
  background-image: url(../flags/1x1/cv.svg);
}

.fi-cw {
  background-image: url(../flags/4x3/cw.svg);
}

.fi-cw.fis {
  background-image: url(../flags/1x1/cw.svg);
}

.fi-cx {
  background-image: url(../flags/4x3/cx.svg);
}

.fi-cx.fis {
  background-image: url(../flags/1x1/cx.svg);
}

.fi-cy {
  background-image: url(../flags/4x3/cy.svg);
}

.fi-cy.fis {
  background-image: url(../flags/1x1/cy.svg);
}

.fi-cz {
  background-image: url(../flags/4x3/cz.svg);
}

.fi-cz.fis {
  background-image: url(../flags/1x1/cz.svg);
}

.fi-de {
  background-image: url(../flags/4x3/de.svg);
}

.fi-de.fis {
  background-image: url(../flags/1x1/de.svg);
}

.fi-dj {
  background-image: url(../flags/4x3/dj.svg);
}

.fi-dj.fis {
  background-image: url(../flags/1x1/dj.svg);
}

.fi-dk {
  background-image: url(../flags/4x3/dk.svg);
}

.fi-dk.fis {
  background-image: url(../flags/1x1/dk.svg);
}

.fi-dm {
  background-image: url(../flags/4x3/dm.svg);
}

.fi-dm.fis {
  background-image: url(../flags/1x1/dm.svg);
}

.fi-do {
  background-image: url(../flags/4x3/do.svg);
}

.fi-do.fis {
  background-image: url(../flags/1x1/do.svg);
}

.fi-dz {
  background-image: url(../flags/4x3/dz.svg);
}

.fi-dz.fis {
  background-image: url(../flags/1x1/dz.svg);
}

.fi-ec {
  background-image: url(../flags/4x3/ec.svg);
}

.fi-ec.fis {
  background-image: url(../flags/1x1/ec.svg);
}

.fi-ee {
  background-image: url(../flags/4x3/ee.svg);
}

.fi-ee.fis {
  background-image: url(../flags/1x1/ee.svg);
}

.fi-eg {
  background-image: url(../flags/4x3/eg.svg);
}

.fi-eg.fis {
  background-image: url(../flags/1x1/eg.svg);
}

.fi-eh {
  background-image: url(../flags/4x3/eh.svg);
}

.fi-eh.fis {
  background-image: url(../flags/1x1/eh.svg);
}

.fi-er {
  background-image: url(../flags/4x3/er.svg);
}

.fi-er.fis {
  background-image: url(../flags/1x1/er.svg);
}

.fi-es {
  background-image: url(../flags/4x3/es.svg);
}

.fi-es.fis {
  background-image: url(../flags/1x1/es.svg);
}

.fi-et {
  background-image: url(../flags/4x3/et.svg);
}

.fi-et.fis {
  background-image: url(../flags/1x1/et.svg);
}

.fi-fi {
  background-image: url(../flags/4x3/fi.svg);
}

.fi-fi.fis {
  background-image: url(../flags/1x1/fi.svg);
}

.fi-fj {
  background-image: url(../flags/4x3/fj.svg);
}

.fi-fj.fis {
  background-image: url(../flags/1x1/fj.svg);
}

.fi-fk {
  background-image: url(../flags/4x3/fk.svg);
}

.fi-fk.fis {
  background-image: url(../flags/1x1/fk.svg);
}

.fi-fm {
  background-image: url(../flags/4x3/fm.svg);
}

.fi-fm.fis {
  background-image: url(../flags/1x1/fm.svg);
}

.fi-fo {
  background-image: url(../flags/4x3/fo.svg);
}

.fi-fo.fis {
  background-image: url(../flags/1x1/fo.svg);
}

.fi-fr {
  background-image: url(../flags/4x3/fr.svg);
}

.fi-fr.fis {
  background-image: url(../flags/1x1/fr.svg);
}

.fi-ga {
  background-image: url(../flags/4x3/ga.svg);
}

.fi-ga.fis {
  background-image: url(../flags/1x1/ga.svg);
}

.fi-gb {
  background-image: url(../flags/4x3/gb.svg);
}

.fi-gb.fis {
  background-image: url(../flags/1x1/gb.svg);
}

.fi-gd {
  background-image: url(../flags/4x3/gd.svg);
}

.fi-gd.fis {
  background-image: url(../flags/1x1/gd.svg);
}

.fi-ge {
  background-image: url(../flags/4x3/ge.svg);
}

.fi-ge.fis {
  background-image: url(../flags/1x1/ge.svg);
}

.fi-gf {
  background-image: url(../flags/4x3/gf.svg);
}

.fi-gf.fis {
  background-image: url(../flags/1x1/gf.svg);
}

.fi-gg {
  background-image: url(../flags/4x3/gg.svg);
}

.fi-gg.fis {
  background-image: url(../flags/1x1/gg.svg);
}

.fi-gh {
  background-image: url(../flags/4x3/gh.svg);
}

.fi-gh.fis {
  background-image: url(../flags/1x1/gh.svg);
}

.fi-gi {
  background-image: url(../flags/4x3/gi.svg);
}

.fi-gi.fis {
  background-image: url(../flags/1x1/gi.svg);
}

.fi-gl {
  background-image: url(../flags/4x3/gl.svg);
}

.fi-gl.fis {
  background-image: url(../flags/1x1/gl.svg);
}

.fi-gm {
  background-image: url(../flags/4x3/gm.svg);
}

.fi-gm.fis {
  background-image: url(../flags/1x1/gm.svg);
}

.fi-gn {
  background-image: url(../flags/4x3/gn.svg);
}

.fi-gn.fis {
  background-image: url(../flags/1x1/gn.svg);
}

.fi-gp {
  background-image: url(../flags/4x3/gp.svg);
}

.fi-gp.fis {
  background-image: url(../flags/1x1/gp.svg);
}

.fi-gq {
  background-image: url(../flags/4x3/gq.svg);
}

.fi-gq.fis {
  background-image: url(../flags/1x1/gq.svg);
}

.fi-gr {
  background-image: url(../flags/4x3/gr.svg);
}

.fi-gr.fis {
  background-image: url(../flags/1x1/gr.svg);
}

.fi-gs {
  background-image: url(../flags/4x3/gs.svg);
}

.fi-gs.fis {
  background-image: url(../flags/1x1/gs.svg);
}

.fi-gt {
  background-image: url(../flags/4x3/gt.svg);
}

.fi-gt.fis {
  background-image: url(../flags/1x1/gt.svg);
}

.fi-gu {
  background-image: url(../flags/4x3/gu.svg);
}

.fi-gu.fis {
  background-image: url(../flags/1x1/gu.svg);
}

.fi-gw {
  background-image: url(../flags/4x3/gw.svg);
}

.fi-gw.fis {
  background-image: url(../flags/1x1/gw.svg);
}

.fi-gy {
  background-image: url(../flags/4x3/gy.svg);
}

.fi-gy.fis {
  background-image: url(../flags/1x1/gy.svg);
}

.fi-hk {
  background-image: url(../flags/4x3/hk.svg);
}

.fi-hk.fis {
  background-image: url(../flags/1x1/hk.svg);
}

.fi-hm {
  background-image: url(../flags/4x3/hm.svg);
}

.fi-hm.fis {
  background-image: url(../flags/1x1/hm.svg);
}

.fi-hn {
  background-image: url(../flags/4x3/hn.svg);
}

.fi-hn.fis {
  background-image: url(../flags/1x1/hn.svg);
}

.fi-hr {
  background-image: url(../flags/4x3/hr.svg);
}

.fi-hr.fis {
  background-image: url(../flags/1x1/hr.svg);
}

.fi-ht {
  background-image: url(../flags/4x3/ht.svg);
}

.fi-ht.fis {
  background-image: url(../flags/1x1/ht.svg);
}

.fi-hu {
  background-image: url(../flags/4x3/hu.svg);
}

.fi-hu.fis {
  background-image: url(../flags/1x1/hu.svg);
}

.fi-id {
  background-image: url(../flags/4x3/id.svg);
}

.fi-id.fis {
  background-image: url(../flags/1x1/id.svg);
}

.fi-ie {
  background-image: url(../flags/4x3/ie.svg);
}

.fi-ie.fis {
  background-image: url(../flags/1x1/ie.svg);
}

.fi-il {
  background-image: url(../flags/4x3/il.svg);
}

.fi-il.fis {
  background-image: url(../flags/1x1/il.svg);
}

.fi-im {
  background-image: url(../flags/4x3/im.svg);
}

.fi-im.fis {
  background-image: url(../flags/1x1/im.svg);
}

.fi-in {
  background-image: url(../flags/4x3/in.svg);
}

.fi-in.fis {
  background-image: url(../flags/1x1/in.svg);
}

.fi-io {
  background-image: url(../flags/4x3/io.svg);
}

.fi-io.fis {
  background-image: url(../flags/1x1/io.svg);
}

.fi-iq {
  background-image: url(../flags/4x3/iq.svg);
}

.fi-iq.fis {
  background-image: url(../flags/1x1/iq.svg);
}

.fi-ir {
  background-image: url(../flags/4x3/ir.svg);
}

.fi-ir.fis {
  background-image: url(../flags/1x1/ir.svg);
}

.fi-is {
  background-image: url(../flags/4x3/is.svg);
}

.fi-is.fis {
  background-image: url(../flags/1x1/is.svg);
}

.fi-it {
  background-image: url(../flags/4x3/it.svg);
}

.fi-it.fis {
  background-image: url(../flags/1x1/it.svg);
}

.fi-je {
  background-image: url(../flags/4x3/je.svg);
}

.fi-je.fis {
  background-image: url(../flags/1x1/je.svg);
}

.fi-jm {
  background-image: url(../flags/4x3/jm.svg);
}

.fi-jm.fis {
  background-image: url(../flags/1x1/jm.svg);
}

.fi-jo {
  background-image: url(../flags/4x3/jo.svg);
}

.fi-jo.fis {
  background-image: url(../flags/1x1/jo.svg);
}

.fi-jp {
  background-image: url(../flags/4x3/jp.svg);
}

.fi-jp.fis {
  background-image: url(../flags/1x1/jp.svg);
}

.fi-ke {
  background-image: url(../flags/4x3/ke.svg);
}

.fi-ke.fis {
  background-image: url(../flags/1x1/ke.svg);
}

.fi-kg {
  background-image: url(../flags/4x3/kg.svg);
}

.fi-kg.fis {
  background-image: url(../flags/1x1/kg.svg);
}

.fi-kh {
  background-image: url(../flags/4x3/kh.svg);
}

.fi-kh.fis {
  background-image: url(../flags/1x1/kh.svg);
}

.fi-ki {
  background-image: url(../flags/4x3/ki.svg);
}

.fi-ki.fis {
  background-image: url(../flags/1x1/ki.svg);
}

.fi-km {
  background-image: url(../flags/4x3/km.svg);
}

.fi-km.fis {
  background-image: url(../flags/1x1/km.svg);
}

.fi-kn {
  background-image: url(../flags/4x3/kn.svg);
}

.fi-kn.fis {
  background-image: url(../flags/1x1/kn.svg);
}

.fi-kp {
  background-image: url(../flags/4x3/kp.svg);
}

.fi-kp.fis {
  background-image: url(../flags/1x1/kp.svg);
}

.fi-kr {
  background-image: url(../flags/4x3/kr.svg);
}

.fi-kr.fis {
  background-image: url(../flags/1x1/kr.svg);
}

.fi-kw {
  background-image: url(../flags/4x3/kw.svg);
}

.fi-kw.fis {
  background-image: url(../flags/1x1/kw.svg);
}

.fi-ky {
  background-image: url(../flags/4x3/ky.svg);
}

.fi-ky.fis {
  background-image: url(../flags/1x1/ky.svg);
}

.fi-kz {
  background-image: url(../flags/4x3/kz.svg);
}

.fi-kz.fis {
  background-image: url(../flags/1x1/kz.svg);
}

.fi-la {
  background-image: url(../flags/4x3/la.svg);
}

.fi-la.fis {
  background-image: url(../flags/1x1/la.svg);
}

.fi-lb {
  background-image: url(../flags/4x3/lb.svg);
}

.fi-lb.fis {
  background-image: url(../flags/1x1/lb.svg);
}

.fi-lc {
  background-image: url(../flags/4x3/lc.svg);
}

.fi-lc.fis {
  background-image: url(../flags/1x1/lc.svg);
}

.fi-li {
  background-image: url(../flags/4x3/li.svg);
}

.fi-li.fis {
  background-image: url(../flags/1x1/li.svg);
}

.fi-lk {
  background-image: url(../flags/4x3/lk.svg);
}

.fi-lk.fis {
  background-image: url(../flags/1x1/lk.svg);
}

.fi-lr {
  background-image: url(../flags/4x3/lr.svg);
}

.fi-lr.fis {
  background-image: url(../flags/1x1/lr.svg);
}

.fi-ls {
  background-image: url(../flags/4x3/ls.svg);
}

.fi-ls.fis {
  background-image: url(../flags/1x1/ls.svg);
}

.fi-lt {
  background-image: url(../flags/4x3/lt.svg);
}

.fi-lt.fis {
  background-image: url(../flags/1x1/lt.svg);
}

.fi-lu {
  background-image: url(../flags/4x3/lu.svg);
}

.fi-lu.fis {
  background-image: url(../flags/1x1/lu.svg);
}

.fi-lv {
  background-image: url(../flags/4x3/lv.svg);
}

.fi-lv.fis {
  background-image: url(../flags/1x1/lv.svg);
}

.fi-ly {
  background-image: url(../flags/4x3/ly.svg);
}

.fi-ly.fis {
  background-image: url(../flags/1x1/ly.svg);
}

.fi-ma {
  background-image: url(../flags/4x3/ma.svg);
}

.fi-ma.fis {
  background-image: url(../flags/1x1/ma.svg);
}

.fi-mc {
  background-image: url(../flags/4x3/mc.svg);
}

.fi-mc.fis {
  background-image: url(../flags/1x1/mc.svg);
}

.fi-md {
  background-image: url(../flags/4x3/md.svg);
}

.fi-md.fis {
  background-image: url(../flags/1x1/md.svg);
}

.fi-me {
  background-image: url(../flags/4x3/me.svg);
}

.fi-me.fis {
  background-image: url(../flags/1x1/me.svg);
}

.fi-mf {
  background-image: url(../flags/4x3/mf.svg);
}

.fi-mf.fis {
  background-image: url(../flags/1x1/mf.svg);
}

.fi-mg {
  background-image: url(../flags/4x3/mg.svg);
}

.fi-mg.fis {
  background-image: url(../flags/1x1/mg.svg);
}

.fi-mh {
  background-image: url(../flags/4x3/mh.svg);
}

.fi-mh.fis {
  background-image: url(../flags/1x1/mh.svg);
}

.fi-mk {
  background-image: url(../flags/4x3/mk.svg);
}

.fi-mk.fis {
  background-image: url(../flags/1x1/mk.svg);
}

.fi-ml {
  background-image: url(../flags/4x3/ml.svg);
}

.fi-ml.fis {
  background-image: url(../flags/1x1/ml.svg);
}

.fi-mm {
  background-image: url(../flags/4x3/mm.svg);
}

.fi-mm.fis {
  background-image: url(../flags/1x1/mm.svg);
}

.fi-mn {
  background-image: url(../flags/4x3/mn.svg);
}

.fi-mn.fis {
  background-image: url(../flags/1x1/mn.svg);
}

.fi-mo {
  background-image: url(../flags/4x3/mo.svg);
}

.fi-mo.fis {
  background-image: url(../flags/1x1/mo.svg);
}

.fi-mp {
  background-image: url(../flags/4x3/mp.svg);
}

.fi-mp.fis {
  background-image: url(../flags/1x1/mp.svg);
}

.fi-mq {
  background-image: url(../flags/4x3/mq.svg);
}

.fi-mq.fis {
  background-image: url(../flags/1x1/mq.svg);
}

.fi-mr {
  background-image: url(../flags/4x3/mr.svg);
}

.fi-mr.fis {
  background-image: url(../flags/1x1/mr.svg);
}

.fi-ms {
  background-image: url(../flags/4x3/ms.svg);
}

.fi-ms.fis {
  background-image: url(../flags/1x1/ms.svg);
}

.fi-mt {
  background-image: url(../flags/4x3/mt.svg);
}

.fi-mt.fis {
  background-image: url(../flags/1x1/mt.svg);
}

.fi-mu {
  background-image: url(../flags/4x3/mu.svg);
}

.fi-mu.fis {
  background-image: url(../flags/1x1/mu.svg);
}

.fi-mv {
  background-image: url(../flags/4x3/mv.svg);
}

.fi-mv.fis {
  background-image: url(../flags/1x1/mv.svg);
}

.fi-mw {
  background-image: url(../flags/4x3/mw.svg);
}

.fi-mw.fis {
  background-image: url(../flags/1x1/mw.svg);
}

.fi-mx {
  background-image: url(../flags/4x3/mx.svg);
}

.fi-mx.fis {
  background-image: url(../flags/1x1/mx.svg);
}

.fi-my {
  background-image: url(../flags/4x3/my.svg);
}

.fi-my.fis {
  background-image: url(../flags/1x1/my.svg);
}

.fi-mz {
  background-image: url(../flags/4x3/mz.svg);
}

.fi-mz.fis {
  background-image: url(../flags/1x1/mz.svg);
}

.fi-na {
  background-image: url(../flags/4x3/na.svg);
}

.fi-na.fis {
  background-image: url(../flags/1x1/na.svg);
}

.fi-nc {
  background-image: url(../flags/4x3/nc.svg);
}

.fi-nc.fis {
  background-image: url(../flags/1x1/nc.svg);
}

.fi-ne {
  background-image: url(../flags/4x3/ne.svg);
}

.fi-ne.fis {
  background-image: url(../flags/1x1/ne.svg);
}

.fi-nf {
  background-image: url(../flags/4x3/nf.svg);
}

.fi-nf.fis {
  background-image: url(../flags/1x1/nf.svg);
}

.fi-ng {
  background-image: url(../flags/4x3/ng.svg);
}

.fi-ng.fis {
  background-image: url(../flags/1x1/ng.svg);
}

.fi-ni {
  background-image: url(../flags/4x3/ni.svg);
}

.fi-ni.fis {
  background-image: url(../flags/1x1/ni.svg);
}

.fi-nl {
  background-image: url(../flags/4x3/nl.svg);
}

.fi-nl.fis {
  background-image: url(../flags/1x1/nl.svg);
}

.fi-no {
  background-image: url(../flags/4x3/no.svg);
}

.fi-no.fis {
  background-image: url(../flags/1x1/no.svg);
}

.fi-np {
  background-image: url(../flags/4x3/np.svg);
}

.fi-np.fis {
  background-image: url(../flags/1x1/np.svg);
}

.fi-nr {
  background-image: url(../flags/4x3/nr.svg);
}

.fi-nr.fis {
  background-image: url(../flags/1x1/nr.svg);
}

.fi-nu {
  background-image: url(../flags/4x3/nu.svg);
}

.fi-nu.fis {
  background-image: url(../flags/1x1/nu.svg);
}

.fi-nz {
  background-image: url(../flags/4x3/nz.svg);
}

.fi-nz.fis {
  background-image: url(../flags/1x1/nz.svg);
}

.fi-om {
  background-image: url(../flags/4x3/om.svg);
}

.fi-om.fis {
  background-image: url(../flags/1x1/om.svg);
}

.fi-pa {
  background-image: url(../flags/4x3/pa.svg);
}

.fi-pa.fis {
  background-image: url(../flags/1x1/pa.svg);
}

.fi-pe {
  background-image: url(../flags/4x3/pe.svg);
}

.fi-pe.fis {
  background-image: url(../flags/1x1/pe.svg);
}

.fi-pf {
  background-image: url(../flags/4x3/pf.svg);
}

.fi-pf.fis {
  background-image: url(../flags/1x1/pf.svg);
}

.fi-pg {
  background-image: url(../flags/4x3/pg.svg);
}

.fi-pg.fis {
  background-image: url(../flags/1x1/pg.svg);
}

.fi-ph {
  background-image: url(../flags/4x3/ph.svg);
}

.fi-ph.fis {
  background-image: url(../flags/1x1/ph.svg);
}

.fi-pk {
  background-image: url(../flags/4x3/pk.svg);
}

.fi-pk.fis {
  background-image: url(../flags/1x1/pk.svg);
}

.fi-pl {
  background-image: url(../flags/4x3/pl.svg);
}

.fi-pl.fis {
  background-image: url(../flags/1x1/pl.svg);
}

.fi-pm {
  background-image: url(../flags/4x3/pm.svg);
}

.fi-pm.fis {
  background-image: url(../flags/1x1/pm.svg);
}

.fi-pn {
  background-image: url(../flags/4x3/pn.svg);
}

.fi-pn.fis {
  background-image: url(../flags/1x1/pn.svg);
}

.fi-pr {
  background-image: url(../flags/4x3/pr.svg);
}

.fi-pr.fis {
  background-image: url(../flags/1x1/pr.svg);
}

.fi-ps {
  background-image: url(../flags/4x3/ps.svg);
}

.fi-ps.fis {
  background-image: url(../flags/1x1/ps.svg);
}

.fi-pt {
  background-image: url(../flags/4x3/pt.svg);
}

.fi-pt.fis {
  background-image: url(../flags/1x1/pt.svg);
}

.fi-pw {
  background-image: url(../flags/4x3/pw.svg);
}

.fi-pw.fis {
  background-image: url(../flags/1x1/pw.svg);
}

.fi-py {
  background-image: url(../flags/4x3/py.svg);
}

.fi-py.fis {
  background-image: url(../flags/1x1/py.svg);
}

.fi-qa {
  background-image: url(../flags/4x3/qa.svg);
}

.fi-qa.fis {
  background-image: url(../flags/1x1/qa.svg);
}

.fi-re {
  background-image: url(../flags/4x3/re.svg);
}

.fi-re.fis {
  background-image: url(../flags/1x1/re.svg);
}

.fi-ro {
  background-image: url(../flags/4x3/ro.svg);
}

.fi-ro.fis {
  background-image: url(../flags/1x1/ro.svg);
}

.fi-rs {
  background-image: url(../flags/4x3/rs.svg);
}

.fi-rs.fis {
  background-image: url(../flags/1x1/rs.svg);
}

.fi-ru {
  background-image: url(../flags/4x3/ru.svg);
}

.fi-ru.fis {
  background-image: url(../flags/1x1/ru.svg);
}

.fi-rw {
  background-image: url(../flags/4x3/rw.svg);
}

.fi-rw.fis {
  background-image: url(../flags/1x1/rw.svg);
}

.fi-sa {
  background-image: url(../flags/4x3/sa.svg);
}

.fi-sa.fis {
  background-image: url(../flags/1x1/sa.svg);
}

.fi-sb {
  background-image: url(../flags/4x3/sb.svg);
}

.fi-sb.fis {
  background-image: url(../flags/1x1/sb.svg);
}

.fi-sc {
  background-image: url(../flags/4x3/sc.svg);
}

.fi-sc.fis {
  background-image: url(../flags/1x1/sc.svg);
}

.fi-sd {
  background-image: url(../flags/4x3/sd.svg);
}

.fi-sd.fis {
  background-image: url(../flags/1x1/sd.svg);
}

.fi-se {
  background-image: url(../flags/4x3/se.svg);
}

.fi-se.fis {
  background-image: url(../flags/1x1/se.svg);
}

.fi-sg {
  background-image: url(../flags/4x3/sg.svg);
}

.fi-sg.fis {
  background-image: url(../flags/1x1/sg.svg);
}

.fi-sh {
  background-image: url(../flags/4x3/sh.svg);
}

.fi-sh.fis {
  background-image: url(../flags/1x1/sh.svg);
}

.fi-si {
  background-image: url(../flags/4x3/si.svg);
}

.fi-si.fis {
  background-image: url(../flags/1x1/si.svg);
}

.fi-sj {
  background-image: url(../flags/4x3/sj.svg);
}

.fi-sj.fis {
  background-image: url(../flags/1x1/sj.svg);
}

.fi-sk {
  background-image: url(../flags/4x3/sk.svg);
}

.fi-sk.fis {
  background-image: url(../flags/1x1/sk.svg);
}

.fi-sl {
  background-image: url(../flags/4x3/sl.svg);
}

.fi-sl.fis {
  background-image: url(../flags/1x1/sl.svg);
}

.fi-sm {
  background-image: url(../flags/4x3/sm.svg);
}

.fi-sm.fis {
  background-image: url(../flags/1x1/sm.svg);
}

.fi-sn {
  background-image: url(../flags/4x3/sn.svg);
}

.fi-sn.fis {
  background-image: url(../flags/1x1/sn.svg);
}

.fi-so {
  background-image: url(../flags/4x3/so.svg);
}

.fi-so.fis {
  background-image: url(../flags/1x1/so.svg);
}

.fi-sr {
  background-image: url(../flags/4x3/sr.svg);
}

.fi-sr.fis {
  background-image: url(../flags/1x1/sr.svg);
}

.fi-ss {
  background-image: url(../flags/4x3/ss.svg);
}

.fi-ss.fis {
  background-image: url(../flags/1x1/ss.svg);
}

.fi-st {
  background-image: url(../flags/4x3/st.svg);
}

.fi-st.fis {
  background-image: url(../flags/1x1/st.svg);
}

.fi-sv {
  background-image: url(../flags/4x3/sv.svg);
}

.fi-sv.fis {
  background-image: url(../flags/1x1/sv.svg);
}

.fi-sx {
  background-image: url(../flags/4x3/sx.svg);
}

.fi-sx.fis {
  background-image: url(../flags/1x1/sx.svg);
}

.fi-sy {
  background-image: url(../flags/4x3/sy.svg);
}

.fi-sy.fis {
  background-image: url(../flags/1x1/sy.svg);
}

.fi-sz {
  background-image: url(../flags/4x3/sz.svg);
}

.fi-sz.fis {
  background-image: url(../flags/1x1/sz.svg);
}

.fi-tc {
  background-image: url(../flags/4x3/tc.svg);
}

.fi-tc.fis {
  background-image: url(../flags/1x1/tc.svg);
}

.fi-td {
  background-image: url(../flags/4x3/td.svg);
}

.fi-td.fis {
  background-image: url(../flags/1x1/td.svg);
}

.fi-tf {
  background-image: url(../flags/4x3/tf.svg);
}

.fi-tf.fis {
  background-image: url(../flags/1x1/tf.svg);
}

.fi-tg {
  background-image: url(../flags/4x3/tg.svg);
}

.fi-tg.fis {
  background-image: url(../flags/1x1/tg.svg);
}

.fi-th {
  background-image: url(../flags/4x3/th.svg);
}

.fi-th.fis {
  background-image: url(../flags/1x1/th.svg);
}

.fi-tj {
  background-image: url(../flags/4x3/tj.svg);
}

.fi-tj.fis {
  background-image: url(../flags/1x1/tj.svg);
}

.fi-tk {
  background-image: url(../flags/4x3/tk.svg);
}

.fi-tk.fis {
  background-image: url(../flags/1x1/tk.svg);
}

.fi-tl {
  background-image: url(../flags/4x3/tl.svg);
}

.fi-tl.fis {
  background-image: url(../flags/1x1/tl.svg);
}

.fi-tm {
  background-image: url(../flags/4x3/tm.svg);
}

.fi-tm.fis {
  background-image: url(../flags/1x1/tm.svg);
}

.fi-tn {
  background-image: url(../flags/4x3/tn.svg);
}

.fi-tn.fis {
  background-image: url(../flags/1x1/tn.svg);
}

.fi-to {
  background-image: url(../flags/4x3/to.svg);
}

.fi-to.fis {
  background-image: url(../flags/1x1/to.svg);
}

.fi-tr {
  background-image: url(../flags/4x3/tr.svg);
}

.fi-tr.fis {
  background-image: url(../flags/1x1/tr.svg);
}

.fi-tt {
  background-image: url(../flags/4x3/tt.svg);
}

.fi-tt.fis {
  background-image: url(../flags/1x1/tt.svg);
}

.fi-tv {
  background-image: url(../flags/4x3/tv.svg);
}

.fi-tv.fis {
  background-image: url(../flags/1x1/tv.svg);
}

.fi-tw {
  background-image: url(../flags/4x3/tw.svg);
}

.fi-tw.fis {
  background-image: url(../flags/1x1/tw.svg);
}

.fi-tz {
  background-image: url(../flags/4x3/tz.svg);
}

.fi-tz.fis {
  background-image: url(../flags/1x1/tz.svg);
}

.fi-ua {
  background-image: url(../flags/4x3/ua.svg);
}

.fi-ua.fis {
  background-image: url(../flags/1x1/ua.svg);
}

.fi-ug {
  background-image: url(../flags/4x3/ug.svg);
}

.fi-ug.fis {
  background-image: url(../flags/1x1/ug.svg);
}

.fi-um {
  background-image: url(../flags/4x3/um.svg);
}

.fi-um.fis {
  background-image: url(../flags/1x1/um.svg);
}

.fi-us {
  background-image: url(../flags/4x3/us.svg);
}

.fi-us.fis {
  background-image: url(../flags/1x1/us.svg);
}

.fi-uy {
  background-image: url(../flags/4x3/uy.svg);
}

.fi-uy.fis {
  background-image: url(../flags/1x1/uy.svg);
}

.fi-uz {
  background-image: url(../flags/4x3/uz.svg);
}

.fi-uz.fis {
  background-image: url(../flags/1x1/uz.svg);
}

.fi-va {
  background-image: url(../flags/4x3/va.svg);
}

.fi-va.fis {
  background-image: url(../flags/1x1/va.svg);
}

.fi-vc {
  background-image: url(../flags/4x3/vc.svg);
}

.fi-vc.fis {
  background-image: url(../flags/1x1/vc.svg);
}

.fi-ve {
  background-image: url(../flags/4x3/ve.svg);
}

.fi-ve.fis {
  background-image: url(../flags/1x1/ve.svg);
}

.fi-vg {
  background-image: url(../flags/4x3/vg.svg);
}

.fi-vg.fis {
  background-image: url(../flags/1x1/vg.svg);
}

.fi-vi {
  background-image: url(../flags/4x3/vi.svg);
}

.fi-vi.fis {
  background-image: url(../flags/1x1/vi.svg);
}

.fi-vn {
  background-image: url(../flags/4x3/vn.svg);
}

.fi-vn.fis {
  background-image: url(../flags/1x1/vn.svg);
}

.fi-vu {
  background-image: url(../flags/4x3/vu.svg);
}

.fi-vu.fis {
  background-image: url(../flags/1x1/vu.svg);
}

.fi-wf {
  background-image: url(../flags/4x3/wf.svg);
}

.fi-wf.fis {
  background-image: url(../flags/1x1/wf.svg);
}

.fi-ws {
  background-image: url(../flags/4x3/ws.svg);
}

.fi-ws.fis {
  background-image: url(../flags/1x1/ws.svg);
}

.fi-ye {
  background-image: url(../flags/4x3/ye.svg);
}

.fi-ye.fis {
  background-image: url(../flags/1x1/ye.svg);
}

.fi-yt {
  background-image: url(../flags/4x3/yt.svg);
}

.fi-yt.fis {
  background-image: url(../flags/1x1/yt.svg);
}

.fi-za {
  background-image: url(../flags/4x3/za.svg);
}

.fi-za.fis {
  background-image: url(../flags/1x1/za.svg);
}

.fi-zm {
  background-image: url(../flags/4x3/zm.svg);
}

.fi-zm.fis {
  background-image: url(../flags/1x1/zm.svg);
}

.fi-zw {
  background-image: url(../flags/4x3/zw.svg);
}

.fi-zw.fis {
  background-image: url(../flags/1x1/zw.svg);
}

.fi-arab {
  background-image: url(../flags/4x3/arab.svg);
}

.fi-arab.fis {
  background-image: url(../flags/1x1/arab.svg);
}

.fi-cefta {
  background-image: url(../flags/4x3/cefta.svg);
}

.fi-cefta.fis {
  background-image: url(../flags/1x1/cefta.svg);
}

.fi-cp {
  background-image: url(../flags/4x3/cp.svg);
}

.fi-cp.fis {
  background-image: url(../flags/1x1/cp.svg);
}

.fi-dg {
  background-image: url(../flags/4x3/dg.svg);
}

.fi-dg.fis {
  background-image: url(../flags/1x1/dg.svg);
}

.fi-eac {
  background-image: url(../flags/4x3/eac.svg);
}

.fi-eac.fis {
  background-image: url(../flags/1x1/eac.svg);
}

.fi-es-ct {
  background-image: url(../flags/4x3/es-ct.svg);
}

.fi-es-ct.fis {
  background-image: url(../flags/1x1/es-ct.svg);
}

.fi-es-ga {
  background-image: url(../flags/4x3/es-ga.svg);
}

.fi-es-ga.fis {
  background-image: url(../flags/1x1/es-ga.svg);
}

.fi-es-pv {
  background-image: url(../flags/4x3/es-pv.svg);
}

.fi-es-pv.fis {
  background-image: url(../flags/1x1/es-pv.svg);
}

.fi-eu {
  background-image: url(../flags/4x3/eu.svg);
}

.fi-eu.fis {
  background-image: url(../flags/1x1/eu.svg);
}

.fi-gb-eng {
  background-image: url(../flags/4x3/gb-eng.svg);
}

.fi-gb-eng.fis {
  background-image: url(../flags/1x1/gb-eng.svg);
}

.fi-gb-nir {
  background-image: url(../flags/4x3/gb-nir.svg);
}

.fi-gb-nir.fis {
  background-image: url(../flags/1x1/gb-nir.svg);
}

.fi-gb-sct {
  background-image: url(../flags/4x3/gb-sct.svg);
}

.fi-gb-sct.fis {
  background-image: url(../flags/1x1/gb-sct.svg);
}

.fi-gb-wls {
  background-image: url(../flags/4x3/gb-wls.svg);
}

.fi-gb-wls.fis {
  background-image: url(../flags/1x1/gb-wls.svg);
}

.fi-ic {
  background-image: url(../flags/4x3/ic.svg);
}

.fi-ic.fis {
  background-image: url(../flags/1x1/ic.svg);
}

.fi-pc {
  background-image: url(../flags/4x3/pc.svg);
}

.fi-pc.fis {
  background-image: url(../flags/1x1/pc.svg);
}

.fi-sh-ac {
  background-image: url(../flags/4x3/sh-ac.svg);
}

.fi-sh-ac.fis {
  background-image: url(../flags/1x1/sh-ac.svg);
}

.fi-sh-hl {
  background-image: url(../flags/4x3/sh-hl.svg);
}

.fi-sh-hl.fis {
  background-image: url(../flags/1x1/sh-hl.svg);
}

.fi-sh-ta {
  background-image: url(../flags/4x3/sh-ta.svg);
}

.fi-sh-ta.fis {
  background-image: url(../flags/1x1/sh-ta.svg);
}

.fi-un {
  background-image: url(../flags/4x3/un.svg);
}

.fi-un.fis {
  background-image: url(../flags/1x1/un.svg);
}

.fi-xk {
  background-image: url(../flags/4x3/xk.svg);
}

.fi-xk.fis {
  background-image: url(../flags/1x1/xk.svg);
}

pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em;
}

code.hljs {
  padding: 3px 5px;
}

.hljs {
  color: var(--accent-swatch-6);
  background: var(--background);
}

.hljs-comment,
.hljs-quote {
  color: var(--muted-text);
  font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: var(--warn-swatch-6);
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: var(--warn-swatch-6);
}

.hljs-literal {
  color: var(--primary-swatch-6);
}

.hljs-addition,
.hljs-attribute,
.hljs-meta .hljs-string,
.hljs-regexp,
.hljs-string {
  color: var(--success-swatch-6);
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: var(--primary-swatch-6);
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: var(--primary-swatch-6);
}

.hljs-built_in,
.hljs-class .hljs-title,
.hljs-title.class_ {
  color: var(--warn-swatch-6);
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 600;
}

.hljs-link {
  text-decoration: underline;
}

/** Styles for Tertiary Button */
/** Styles for Primary Button */
/** Styles for Button Focus */
.ctx-button {
  @apply outline-none flex flex-row items-center justify-center border-solid border-2 gap-2 transition-all select-none leading-none box-border;
  border-color: transparent;
}
.ctx-button--sans {
  @apply font-sans;
}
.ctx-button--mono {
  @apply font-mono;
}
.ctx-button--secondary.ctx-button--color-primary {
  color: var(--primary-swatch-6);
  background-color: var(--primary-swatch-3);
}
.ctx-button--secondary.ctx-button--color-primary :active, .ctx-button--secondary.ctx-button--color-primary:not(button:disabled):hover, .ctx-button--secondary.ctx-button--color-primary:not(button:disabled):focus-visible {
  background-color: var(--primary-swatch-3);
  color: var(--primary-swatch-7);
  border-color: var(--primary-swatch-7);
}
.ctx-button--secondary.ctx-button--color-accent {
  color: var(--accent-swatch-6);
  background-color: var(--accent-swatch-3);
}
.ctx-button--secondary.ctx-button--color-accent :active, .ctx-button--secondary.ctx-button--color-accent:not(button:disabled):hover, .ctx-button--secondary.ctx-button--color-accent:not(button:disabled):focus-visible {
  background-color: var(--accent-swatch-3);
  color: var(--accent-swatch-7);
  border-color: var(--accent-swatch-7);
}
.ctx-button--secondary.ctx-button--color-warn {
  color: var(--warn-swatch-6);
  background-color: var(--warn-swatch-3);
}
.ctx-button--secondary.ctx-button--color-warn :active, .ctx-button--secondary.ctx-button--color-warn:not(button:disabled):hover, .ctx-button--secondary.ctx-button--color-warn:not(button:disabled):focus-visible {
  background-color: var(--warn-swatch-3);
  color: var(--warn-swatch-7);
  border-color: var(--warn-swatch-7);
}
.ctx-button--primary.ctx-button--color-primary {
  color: var(--primary-swatch-1);
  background-color: var(--primary-swatch-6);
}
.ctx-button--primary.ctx-button--color-primary .mat-mdc-progress-spinner circle,
.ctx-button--primary.ctx-button--color-primary .mat-mdc-progress-spinner circle {
  stroke: var(--primary-swatch-1);
}
.ctx-button--primary.ctx-button--color-primary :active, .ctx-button--primary.ctx-button--color-primary:not(button:disabled):hover, .ctx-button--primary.ctx-button--color-primary:not(button:disabled):focus-visible {
  background-color: var(--primary-swatch-7);
  color: var(--primary-swatch-2);
  border-color: var(--primary-swatch-7);
}
.ctx-button--primary.ctx-button--color-accent {
  color: var(--accent-swatch-1);
  background-color: var(--accent-swatch-6);
}
.ctx-button--primary.ctx-button--color-accent .mat-mdc-progress-spinner circle,
.ctx-button--primary.ctx-button--color-accent .mat-mdc-progress-spinner circle {
  stroke: var(--accent-swatch-1);
}
.ctx-button--primary.ctx-button--color-accent :active, .ctx-button--primary.ctx-button--color-accent:not(button:disabled):hover, .ctx-button--primary.ctx-button--color-accent:not(button:disabled):focus-visible {
  background-color: var(--accent-swatch-7);
  color: var(--accent-swatch-2);
  border-color: var(--accent-swatch-7);
}
.ctx-button--primary.ctx-button--color-warn {
  color: var(--warn-swatch-1);
  background-color: var(--warn-swatch-6);
}
.ctx-button--primary.ctx-button--color-warn .mat-mdc-progress-spinner circle,
.ctx-button--primary.ctx-button--color-warn .mat-mdc-progress-spinner circle {
  stroke: var(--warn-swatch-1);
}
.ctx-button--primary.ctx-button--color-warn :active, .ctx-button--primary.ctx-button--color-warn:not(button:disabled):hover, .ctx-button--primary.ctx-button--color-warn:not(button:disabled):focus-visible {
  background-color: var(--warn-swatch-7);
  color: var(--warn-swatch-2);
  border-color: var(--warn-swatch-7);
}
.ctx-button--sm {
  @apply px-2 py-1 rounded-md text-xs;
}
.ctx-button--base {
  @apply px-2 py-1.5 rounded-md text-sm;
}
.ctx-button--lg {
  @apply px-2 py-2 rounded-md text-base;
}

.ctx-button-group {
  @apply inline-flex items-center justify-start;
}
.ctx-button-group .ctx-button {
  @apply relative;
}
.ctx-button-group .ctx-button:first-child:not(:only-child) {
  @apply rounded-r-none flex-grow;
}
.ctx-button-group .ctx-button:not(:first-child):not(:last-child):not(:only-child) {
  @apply rounded-none;
}
.ctx-button-group .ctx-button:last-child:not(:only-child) {
  @apply rounded-l-none;
}

@layer utilities {
  .ctx-card {
    @apply border-solid border box-border rounded-md flex flex-col min-w-[12rem];
    background-color: var(--card);
    border-color: var(--divider);
  }
  .ctx-card__header {
    @apply flex flex-row justify-between items-center flex-wrap py-2 px-6 border-b;
  }
  .ctx-card__content {
    @apply px-6 py-4 flex-grow;
  }
  .ctx-card__footer {
    @apply border-t flex flex-row flex-wrap gap-4 justify-end items-center p-6;
  }
  .ctx-card-list {
    @apply box-border overflow-auto w-full pb-2 flex flex-col flex-wrap gap-4 sm:flex-row;
  }
  .two-col-layout {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-4 py-4 border-b;
  }
}
.ctx-table__toolbar {
  background-color: var(--card);
  border-color: var(--divider);
  border-bottom-style: solid;
}
.ctx-table__content__wrapper {
  @apply h-[calc(100vh_-_20.25rem)] w-full overflow-auto box-border;
  background-color: var(--card);
}
.ctx-table__content__loader {
  @apply h-[calc(100vh_-_20.25rem)] w-full box-border flex justify-center items-center z-10;
  background-color: var(--card);
}
.ctx-table__paginator {
  background-color: var(--card);
  border-color: var(--divider);
}
.ctx-table--object tr > th {
  border-color: var(--divider);
  background-color: var(--card);
}

td.mat-mdc-cell,
th.mat-mdc-header-cell {
  border-color: var(--divider);
}

.mdc-data-table__row:last-child td.mat-mdc-cell {
  border-bottom: 2px dashed var(--divider);
}

.ctx-filter__active-filters {
  border-color: var(--divider);
}
.ctx-filter__connected-overlay {
  background-color: var(--card);
}

.ctx-stepper {
  border-color: var(--divider);
}
.ctx-stepper__header__button {
  @apply border-0 px-2 py-1 text-base border-b-2;
  color: var(--accent-swatch-8);
  border-color: var(--divider);
}
.ctx-stepper__header__button:not(button:disabled):hover .ctx-stepper__header__button__text, .ctx-stepper__header__button:active .ctx-stepper__header__button__text, .ctx-stepper__header__button:focus-visible .ctx-stepper__header__button__text {
  background-color: var(--accent-swatch-2);
}
.ctx-stepper__header__button--active {
  @apply border-b-4;
  color: var(--accent-swatch-10);
  border-color: var(--accent-swatch-10);
}
.ctx-stepper__header__button--active:not(button:disabled):hover .ctx-stepper__header__button__text, .ctx-stepper__header__button--active:active .ctx-stepper__header__button__text, .ctx-stepper__header__button--active:focus-visible .ctx-stepper__header__button__text {
  background-color: var(--accent-swatch-3);
}
.ctx-stepper__header__button--active .ctx-stepper__header__button__number {
  color: var(--accent-swatch-9);
  background-color: var(--accent-swatch-3);
}
.ctx-stepper__header__button--active .ctx-stepper__header__button__text {
  background-color: var(--accent-swatch-3);
}

.ctx-tag {
  border-color: var(--accent-swatch-4);
  color: var(--text);
}
.ctx-tag__hole {
  background: var(--accent-swatch-3);
}

::-webkit-scrollbar {
  @apply w-2 h-2 p-1;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: color-mix(in oklch, var(--accent-swatch-8), transparent 80%);
}
::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:active {
  background-color: var(--hover);
}

::-webkit-scrollbar-track {
  background-color: var(--accent-swatch-1);
}

::-webkit-scrollbar-corner {
  background-color: var(--accent-swatch-2);
}

.uppy-DragDrop-container {
  @apply flex flex-row items-center justify-center max-w-full font-sans rounded-lg cursor-pointer;
  background-color: var(--accent-swatch-2);
}
.uppy-DragDrop-container::-moz-focus-inner {
  border: 0;
}
.uppy-DragDrop-container:focus {
  @apply outline outline-1;
  outline-color: var(--primary-swatch-8);
}
.uppy-DragDrop-inner {
  @apply px-9 py-4 text-center m-0;
}
.uppy-DragDrop-arrow {
  @apply hidden;
  fill: var(--divider);
}
.uppy-DragDrop--isDragDropSupported {
  border: 2px dashed var(--divider);
}
.uppy-DragDrop--isDraggingOver {
  border: 2px dashed var(--hover);
}
.uppy-DragDrop--isDraggingOver .uppy-DragDrop-arrow {
  fill: var(--hover);
}
.uppy-DragDrop-label {
  @apply block text-base;
  color: var(--text);
}
.uppy-DragDrop-browse {
  @apply cursor-pointer;
  color: var(--primary-swatch-8);
}
.uppy-DragDrop-note {
  color: var(--text);
}

@layer utilities {
  .chart-canvas {
    @apply h-[30em] w-full;
  }
  .chart-overlay {
    @apply absolute  top-0 left-0 w-full h-full flex justify-center items-center z-10 bg-card;
  }
}
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.mat-mdc-form-field--xs {
  --mat-form-field-container-height: 36px;
  --mat-form-field-filled-label-display: none;
  --mat-form-field-container-vertical-padding: 6px;
  --mat-form-field-filled-with-label-container-padding-top: 6px;
  --mat-form-field-filled-with-label-container-padding-bottom: 6px;
}
.mat-mdc-form-field--xs .mdc-text-field__input {
  padding-top: 2px;
}

.mat-mdc-form-field-icon-suffix {
  @apply mx-2 !important;
}

.mat-mdc-table.mat-table--paginated .mat-mdc-header-cell,
.mat-mdc-table.mat-table--paginated .mat-mdc-cell {
  @apply whitespace-nowrap;
}
.mat-mdc-table.mat-table--paginated .mat-mdc-header-cell {
  @apply whitespace-nowrap;
}
.mat-mdc-table.mat-table--paginated .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
  background-color: inherit;
}

.mat-mdc-option {
  @apply text-sm min-h-[36px] !important;
}

:root .mat-mdc-option:focus.mdc-list-item,
:root .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: var(--hover);
}
:root .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: var(--hover);
}
:root .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  background-color: var(--hover);
}

.mat-mdc-select {
  @apply text-sm !important;
}

:root {
  --mat-select-disabled-arrow-color: var(--divider);
  --mat-select-enabled-arrow-color: var(--text);
}

.mat-mdc-menu-item {
  @apply text-sm min-h-[36px] !important;
}

:root .mat-mdc-menu-item:not([disabled]):hover {
  background-color: var(--hover);
}
:root .mat-mdc-menu-item:not([disabled]):focus {
  background-color: var(--hover);
}
:root .mat-mdc-menu-item:not([disabled]):focus-visible {
  background-color: var(--hover);
}
:root .mat-mdc-menu-item:not([disabled]):focus-within {
  background-color: var(--hover);
}
:root .mat-mdc-menu-item:not([disabled]).mat-mdc-menu-item-highlighted {
  background-color: var(--hover);
}

.mat-mdc-dialog-actions {
  justify-content: end !important;
}

.mdc-dialog__actions {
  @apply gap-2;
  @apply p-4 !important;
}

.mdc-dialog__content {
  @apply border-y border-x-0 border-solid;
}

.mdc-dialog__title + .mdc-dialog__content,
.mdc-dialog__header + .mdc-dialog__content {
  padding-top: 20px !important;
}

.mat-mdc-checkbox + label {
  @apply opacity-80 pl-2 mt-1 mb-4 block;
}

:root {
  --mdc-checkbox-selected-checkmark-color: var(--accent-swatch-2);
  --mdc-checkbox-disabled-selected-icon-color: var(--accent-swatch-2);
  --mdc-checkbox-disabled-unselected-icon-color: var(--disabled);
  --mdc-checkbox-disabled-selected-checkmark-color: var(--disabled);
}

.ctx-card--header .mat-h2,
.mat-headline-6,
.mat-typography .mat-h2,
.mat-typography .mat-headline-6,
.mat-typography h2 {
  @apply my-2;
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  --mdc-snackbar-supporting-text-color: var(--text);
}