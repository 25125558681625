.ctx-stepper {
  border-color: var(--divider);

  &__header {
    &__button {
      @apply border-0 px-2 py-1 text-base border-b-2;
      color: var(--accent-swatch-8);
      border-color: var(--divider);

      &:not(button:disabled):hover,
      &:active,
      &:focus-visible {
        .ctx-stepper__header__button__text {
          background-color: var(--accent-swatch-2);
        }
      }

      &--active {
        @apply border-b-4;
        color: var(--accent-swatch-10);
        border-color: var(--accent-swatch-10);

        &:not(button:disabled):hover,
        &:active,
        &:focus-visible {
          .ctx-stepper__header__button__text {
            background-color: var(--accent-swatch-3);
          }
        }

        .ctx-stepper__header__button__number {
          color: var(--accent-swatch-9);
          background-color: var(--accent-swatch-3);
        }

        .ctx-stepper__header__button__text {
          background-color: var(--accent-swatch-3);
        }
      }
    }
  }
}
