pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em;
}

code.hljs {
  padding: 3px 5px;
}

.hljs {
  color: var(--accent-swatch-6);
  background: var(--background);
}

.hljs-comment,
.hljs-quote {
  color: var(--muted-text);
  font-style: italic;
}

.hljs-doctag,
.hljs-formula,
.hljs-keyword {
  color: var(--warn-swatch-6);
}

.hljs-deletion,
.hljs-name,
.hljs-section,
.hljs-selector-tag,
.hljs-subst {
  color: var(--warn-swatch-6);
}

.hljs-literal {
  color: var(--primary-swatch-6);
}

.hljs-addition,
.hljs-attribute,
.hljs-meta .hljs-string,
.hljs-regexp,
.hljs-string {
  color: var(--success-swatch-6);
}

.hljs-attr,
.hljs-number,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-pseudo,
.hljs-template-variable,
.hljs-type,
.hljs-variable {
  color: var(--primary-swatch-6);
}

.hljs-bullet,
.hljs-link,
.hljs-meta,
.hljs-selector-id,
.hljs-symbol,
.hljs-title {
  color: var(--primary-swatch-6);
}

.hljs-built_in,
.hljs-class .hljs-title,
.hljs-title.class_ {
  color: var(--warn-swatch-6);
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: 600;
}

.hljs-link {
  text-decoration: underline;
}
