// Unlike Material Design, we position buttons to the right end in LTR view.
.mat-mdc-dialog-actions {
  justify-content: end !important;
}

.mdc-dialog__actions {
  @apply gap-2;
  @apply p-4 #{!important};
}

.mdc-dialog__content {
  @apply border-y border-x-0 border-solid;
}

.mdc-dialog__title + .mdc-dialog__content,
.mdc-dialog__header + .mdc-dialog__content {
  padding-top: 20px !important;
}
