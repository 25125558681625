:root {
  --font-sans: '';
  // --background: var(--background);
  --foreground: var(--text);
  // --card: var(--card);
  --card-foreground: var(--text);
  --popover: 0 0% 100%;
  --popover-foreground: 240 10% 3.9%;
  --primary: var(--primary-swatch-6);
  --primary-foreground: 0 0% 98%;
  --secondary: 240 4.8% 95.9%;
  --secondary-foreground: 240 5.9% 10%;
  --muted: 240 4.8% 95.9%;
  --muted-foreground: 240 3.8% 46.1%;
  --accent: var(--accent-swatch-6);
  --accent-foreground: 240 5.9% 10%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 0 0% 98%;
  --border: var(--divider);
  --input: 240 5.9% 90%;
  --ring: 240 5.9% 10%;
  --radius: 0.5rem;
}
