@layer utilities {
  .ctx-card {
    @apply border-solid border box-border rounded-md flex flex-col min-w-[12rem];
    background-color: var(--card);
    border-color: var(--divider);

    &__header {
      @apply flex flex-row justify-between items-center flex-wrap py-2 px-6 border-b;
    }

    &__content {
      @apply px-6 py-4 flex-grow;
    }

    &__footer {
      @apply border-t flex flex-row flex-wrap gap-4 justify-end items-center p-6;
    }
  }

  .ctx-card-list {
    @apply box-border overflow-auto w-full pb-2 flex flex-col flex-wrap gap-4 sm:flex-row;
  }

  .two-col-layout {
    @apply grid grid-cols-1 lg:grid-cols-2 gap-4 py-4 border-b;
  }
}
