@use '@angular/material' as mat;

// https://stackoverflow.com/questions/74500132/angular-15-material-bug-input-on-focus-when-using-tailwindcss
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-right-style: hidden;
}

.mat-mdc-form-field--xs {
  @include mat.form-field-density(-5);

  // Adjustment for placeholder alignment
  .mdc-text-field__input {
    padding-top: 2px;
  }
}
.mat-mdc-form-field-icon-suffix {
  @apply mx-2 #{!important};
}
