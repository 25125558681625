// Drag Drop
.uppy-DragDrop {
  &-container {
    @apply flex flex-row items-center justify-center max-w-full font-sans rounded-lg cursor-pointer;
    background-color: var(--accent-swatch-2);

    &::-moz-focus-inner {
      border: 0;
    }

    &:focus {
      @apply outline outline-1;
      outline-color: var(--primary-swatch-8);
    }
  }

  &-inner {
    @apply px-9 py-4 text-center m-0;
  }

  &-arrow {
    @apply hidden;
    fill: var(--divider);
  }

  &--isDragDropSupported {
    border: 2px dashed var(--divider);
  }

  &--isDraggingOver {
    border: 2px dashed var(--hover);
  }

  &--isDraggingOver &-arrow {
    // TODO
    fill: var(--hover);
  }

  &-label {
    @apply block text-base;
    color: var(--text);
  }

  &-browse {
    @apply cursor-pointer;
    color: var(--primary-swatch-8);
  }

  &-note {
    color: var(--text);
  }
}
